import React, { useEffect } from "react";
import "./landlord.css";
import { useAuth } from "../../../context/AuthContext";
import visionImg from "../../../assets/illustrations/VISION.png";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function Landlord() {
  const auth = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <div className="rent-out-space-landlord-section">
        <div className="rent-out-space-landlord-info">
          <h1 id="rent-out-space-page-title">Hyr ut lokal</h1>
          <h2>Marknadsplatsen för uthyrning av lokaler i andrahand och förstahand</h2>
          <p>
            Hyr ut lokal enkelt och flexibelt med Vakansa. Vi erbjuder en mångsidig plattform där du kan anpassa din
            uthyrning efter dina önskemål, oavsett om du föredrar korttids- eller långtidsuthyrning.
          </p>
          <br />
          <p>
            Du kan helt enkelt bestämma om du vill hyra ut några timmar, dagar eller mer långvarigt så som veckor,
            månader eller flera år!
          </p>
          <br />
          <p>
            Genom att registrera dig här nedan kan du snabbt och enkelt skapa annonser som laddas upp på vår fina sida
            för Lediga lokaler och visas för potentiella hyresgäster.
          </p>
          <br />
          <p>Börja hyra ut din lokal idag!</p>
          <br />
          <RoundedLinkButton
            data-testid="landlord-register-btn"
            label={!auth.user ? "Registrera ett konto här" : "Skapa annons"}
            href={!auth.user ? "/registrera-konto" : "/mina-sidor/skapa-annons"}
          ></RoundedLinkButton>
        </div>
        <img
          id="how-it-works-landlord-image"
          src={visionImg}
          alt="Neighbourhood with tall buildings, people and trees"
        />
        <div className="rent-out-space-landlord-steps">
          <div>
            <div>
              <span>1</span>
            </div>
            <b>Registrera ett konto</b>
            <p>
              Genom att registrera ett konto får du tillgång till Vakansas portal där du kan skapa annonser på lokalen
              eller lokalerna du vill hyra ut.
            </p>
          </div>
          <div>
            <div>
              <span>2</span>
            </div>
            <b>Vi matchar din lokal</b>
            <p>
              Efter att du har skapat och publicerat dina annonser börjar vi marknadsföra din yta och jobbar aktiv med
              att hitta hyresgäster som är intresserade av din lokal
            </p>
          </div>
          <div>
            <div>
              <span>3</span>
            </div>
            <b>Visning & avtalsskrivning</b>
            <p>
              Vid intresse skickar hyresgästerna in intresseanmälningar som trillar in i din mejl. Därefter tar du
              kontakt med hyresgästen, bokar en visning och tecknar avtal om det känns bra!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
