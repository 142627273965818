import React from "react";
import Landlord from "./Landlord/Landlord";
import Pricing from "./Pricing/Pricing";
import SEOTags from "../../utils/SEOTags";
import "./rentOutSpacePage.css";

function RentOutSpace() {
  return (
    <>
      <SEOTags
        title={"Hyr ut lokaler per timme, dag, månader eller år via Vakansa!"}
        description={
          "Hyresgäster sökes till era lokaler? Hyr ut lokaler enkelt & smidigt via Vakansa! Annonsera både ytor i förstahand & andrahand gratis!"
        }
        canonicalUrl={"/hyr-ut-lokal"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="rent-out-space-outer">
        <Landlord />
        <Pricing />
      </main>
    </>
  );
}

export default RentOutSpace;
