import React, { useEffect } from "react";
import "./faqSection.css";
import question from "../../assets/illustrations/questions-2.png";
import questionPerson from "../../assets/illustrations/question-person.png";
import useCheckIfLarge from "../../customHooks/useCheckIfLarge";
import landlord from "../../assets/illustrations/Search2.png";
import renter from "../../assets/illustrations/Search.png";
import SEOTags from "../../utils/SEOTags";

function FAQ() {
  const isLarge = useCheckIfLarge();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const landlordFAQ = () => {
    window.location.href = "/fragor-och-svar/hyresvard";
  };

  const renterFAQ = () => {
    window.location.href = "/fragor-och-svar/hyresgast";
  };

  const vakansaFAQ = () => {
    window.location.href = "/fragor-och-svar/vad-ar-vakansa";
  };

  return (
    <div className="faq-section-outer">
      <SEOTags
        title={"Få svar om hur du hyr och hyr ut snabbt & enkelt på Vakansa!   "}
        description={
          "Vill du veta vad det innebär att vara hyresvärd & hyresgäst på Vakansa? Få svar på dina vanligaste frågor snabbt & smidigt här!"
        }
        canonicalUrl={"/fragor-och-svar"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="faq-section-background">
        <div className="faq-section">
          <div className="faq-section-top-text" aria-labelledby="faq-title" aria-describedby="faq-description">
            <h1 id="faq-title">Frågor och svar</h1>
            <h2 id="faq-description">Hur kan vi hjälpa dig?</h2>
          </div>
          <div className="faq-section-flex">
            <div className="faq-section-content">
              {isLarge ? (
                <>
                  <div
                    className="faq-section-options"
                    onClick={landlordFAQ}
                    role="button"
                    aria-label="Vanliga frågor för hyresvärdar"
                  >
                    <img src={landlord} alt="Hus med checkbox" />
                    <p>Hyresvärd</p>
                  </div>
                  <div
                    className="faq-section-options"
                    onClick={renterFAQ}
                    role="button"
                    aria-label="Vanliga frågor för hyresgäster"
                  >
                    <img src={renter} alt="Hus med förstorningsglas" />
                    <p>Hyresgäst</p>
                  </div>
                  <div
                    className="faq-section-options"
                    onClick={vakansaFAQ}
                    role="button"
                    aria-label="Vanliga frågor om Vakansa"
                  >
                    <img src={question} alt="Bild på frågetecken" />
                    <p>Vad är vakansa?</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="faq-section-options" id="faq-item-1" onClick={vakansaFAQ}>
                    <img src={question} alt="Bild på frågetecken" />
                    <p>Vad är Vakansa?</p>
                  </div>
                  <div className="faq-option-flex">
                    <div className="faq-section-options" onClick={landlordFAQ}>
                      <img src={landlord} alt="Hus med checkbox" />
                      <p>Hyresvärd</p>
                    </div>
                    <div className="faq-section-options" onClick={renterFAQ}>
                      <img src={renter} alt="Hus med förstorningsglas" />
                      <p>Hyresgäst</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="faq-background-img">
              <img src={questionPerson} alt="Person with a question mark above it's head looking at phone screen" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default FAQ;
