import React, { useEffect, useState, useContext } from "react";
import FacilitiesForm from "../FacilitiesForm/FacilitiesForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import importedMunicipalities from "../../../../assets/municipalities.json";
import axios from "axios";
import AdPreviewContext from "../../../../context/AdPreviewContext";
import { Image, Transformation } from "cloudinary-react";
import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import importedRooms from "../../../../assets/roomTypes.json";
import { requiredFieldsCheck, authenticateForm } from "../../utilities/formAuth";
import Loading from "../Loading/Loading";
import { backend } from "../../../../backend";
import { logAxiosError } from "../../../../logging/error";
import WarningModal from "../../../../components/WarningModal/WarningModal";
import { useCallbackPrompt } from "../../../../context/useCallbackPrompt";
import { useAuth } from "../../../../context/AuthContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./editAd.css";
import RoundedButton from "../../../../components/Buttons/RoundedButton/RoundedButton";
import { Helmet } from "react-helmet-async";

const defaultFormData = {
  name: "",
  owner_name: "",
  address: "",
  city: "",
  municipality: "",
  zip_code: "",
  type_of_room: "",
  area: "",
  capacity: "",
  workspaces: "",
  facilities: [],
  description: "",
  images: [],
  timeslots: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  fully_vacant: true,
  hourly_rent: "",
  daily_rent: "",
  monthly_rent: "",
  workspace_rent: "",
  vat_tax_required: false,
};
const { roomTypes } = importedRooms;
const { municipalities } = importedMunicipalities;

function EditAd(props) {
  const [formData, setFormData] = useState(defaultFormData);
  const [newImages, setNewImages] = useState([]);
  const [imageError, setImageError] = useState(null);
  // These are to prevent user from accessing preview page without necessary info
  const [disableSubmit, setDisableSubmit] = useState(true);

  // Set to true when going to preview, triggers useEffect that navigates to preview
  const [viewPreview, setViewPreview] = useState(false);

  const [pageHeading, setPageHeading] = useState("");
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const { adPreviewInfo, setAdPreviewInfo } = useContext(AdPreviewContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const auth = useAuth();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // For loading state, while waiting
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ImageTooBig, setImageTooBig] = useState(null);

  // Warn users when leaving that their ad is not saved
  // enable and disable the prompt with this state
  const [showPrompt, setShowPrompt] = useState(false);
  const { showPrompt: showWarningModal, confirmNavigation, cancelNavigation } = useCallbackPrompt(showPrompt);

  const fetchFormData = async () => {
    try {
      const { data } = await axios.get(backend("/api/listings/get-by-id"), {
        params: {
          token: localStorage.getItem("token"),
          id: id,
        },
      });
      if (data.status) {
        setFormData({ ...formData, ...data.ad });
        setPageHeading("Redigera annons");
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
    }
  };

  useEffect(() => {
    const { info, images, hasChanged } = adPreviewInfo;

    if (hasChanged) {
      setShowPrompt(true);
    }

    // If user returns from preview, set updated info and images
    if (location.state?.from === "preview") {
      setFormData(info);
      setNewImages(images);

      // And reset previewInfo
      setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
      setPageHeading(info?.name ? info.name : "Redigera annons");
    } else {
      // Else fetch ad info
      fetchFormData();
    }

    // eslint-disable-next-line
  }, [location]);

  // Disable submit and preview if required fields are empty
  useEffect(() => {
    setDisableSubmit(!requiredFieldsCheck(formData, newImages));
  }, [formData, newImages]);

  const saveAdHandler = (activate) => {
    const active = activate === "activate" ? true : activate === "inactivate" ? false : formData?.active;

    // new array of invalid fields
    const currentInvalidFields = [];

    // Check required fields
    if (!formData.owner_name) currentInvalidFields.push("owner_name");
    if (!formData.address) currentInvalidFields.push("address");
    if (!formData.city) currentInvalidFields.push("city");
    if (!formData.municipality || formData.municipality === "Välj kommun") currentInvalidFields.push("municipality");
    if (!formData.zip_code || formData.zip_code.length !== 5) currentInvalidFields.push("zip_code");
    if (!formData.type_of_room || formData.type_of_room === "Typ av lokal") currentInvalidFields.push("type_of_room");
    if (!formData.area) currentInvalidFields.push("area");
    if (!formData.description) currentInvalidFields.push("description");

    // Check if at least one rent price is provided
    if (!formData.hourly_rent && !formData.daily_rent && !formData.monthly_rent && !formData.workspace_rent) {
      currentInvalidFields.push("rent");
    }

    // Check images
    if (!formData.images || (formData.images.length < 1 && newImages.length < 1)) {
      currentInvalidFields.push("images");
    }

    // Update invalid fields state with the new array
    setInvalidFields(currentInvalidFields);

    // Only proceed if there are no invalid fields
    if (currentInvalidFields.length === 0 && authenticateForm(formData, newImages, setInvalidFields)) {
      setLoading(true);
      saveChangesRequest(active);
    }
  };

  const saveChangesRequest = async (active) => {
    try {
      const { data } = await axios.patch(backend("/api/listings/edit"), {
        token: localStorage.getItem("token"),
        ad_id: id,
        data: {
          ...formData,
          images: [...formData?.images, ...newImages],
          active: active,
        },
      });

      if (data.status) {
        uploadImages();
      }
    } catch (error) {
      logAxiosError(error);
      setLoading(false);
      setShowErrorModal(true);
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const uploadImages = async () => {
    const imageData = new FormData();
    let errorOccured = false;

    for (let image of newImages) {
      imageData.append("file", image.file);
      imageData.append("upload_preset", "oi2aoqjs");
      imageData.append("public_id", `/${id}/${image.id}`);

      const response = await axios.post("https://api.cloudinary.com/v1_1/vakansa/image/upload", imageData);

      // TODO: will non 200 response throw and thus need try catch?
      if (response.status !== 200) {
        errorOccured = true;
      }
    }

    // All steps are done, set loading to false
    // and set custom error message if errors occured
    setLoading(false);
    setErrorMessage(
      errorOccured
        ? "Ett problem uppstod under bild uppladdningen. Det kan ha hänt att inte alla bilder blev uppladdade."
        : ""
    );

    // Show modal passed as argument
    if (!errorOccured) {
      setShowPrompt(false);
      setShowSaveChangesModal(true);
      setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
    } else {
      setShowPrompt(true);
      setShowErrorModal(true);
    }
  };

  // Run on every on change handler
  const formHasChanged = () => {
    // Set hasChanged to true after changing form
    setAdPreviewInfo((prev) => ({ ...prev, hasChanged: true }));

    // Show prompt after form has changed
    setShowPrompt(true);
  };

  const onChangeHandler = (e) => {
    const isNumericInput = e.target.inputMode === "numeric";
    const isNumericValue = new RegExp(/^(\/?[0-9])*\/?$/).test(e.target.value);

    // Don't set value if non-numeric value (excl. spaces)
    // is typed into a numeric text field. For example:
    // <input type="text" inputMode="numeric" />
    if (isNumericInput && !isNumericValue) return;

    // Run on every valid onChange
    formHasChanged();

    // Else set value as usual
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onImagesChange = (e) => {
    const files = e.target.files;

    // User can only add max 12 images at once (max is 12)
    if (files.length < 1 || files.length > 12) return;
    if (newImages.length + files.length > 12) {
      setImageTooBig("Du kan inte ladda upp fler än 12 bilder. Du har nått maximum");
      return;
    }

    // Check file sizes and reject if larger than 10MB
    const newImageObjects = [...files]
      .filter((file) => file.size <= 10 * 1024 * 1024)
      .map((file) => {
        // Check if an image with the same name already exists in the newImages state
        const existingImage = newImages.find((image) => image.file.name === file.name);
        if (existingImage) {
          console.warn(`Image "${file.name}" already exists in the list`);
          setImageTooBig(`Image "${file.name}" already exists in the list`);
          return null; // return null if image already exists
        }
        return {
          url: URL.createObjectURL(file),
          id: uuidv4(),
          file,
        };
      })
      .filter((image) => image !== null); // filter out null values from the array

    // Notify user if any images were rejected due to size
    const rejectedImages = [...files].filter((file) => file.size > 10 * 1024 * 1024);
    if (rejectedImages.length > 0) {
      setImageTooBig(`Bilden du försökte ladda upp var större än 10MB. Försök med en mindre bild`);
    } else {
      setImageTooBig(null); // set imageTooBig to null if all images were within size limits
    }

    // Run on every valid onChange
    formHasChanged();

    setNewImages((prevData) => [...prevData, ...newImageObjects]);
  };

  // For removing images the user has just added
  const removeNewImageHandler = (image) => {
    // Run on every valid onChange
    formHasChanged();

    setNewImages((prevData) => prevData.filter((currentImage) => currentImage.id !== image));
  };

  // For removing images already stored in cloudinary
  const removeOldImageHandler = (image) => {
    // Run on every valid onChange
    formHasChanged();

    setFormData((prevData) => ({
      ...prevData,
      images: formData?.images.filter((currentImage) => currentImage !== image),
    }));
  };

  useEffect(() => {
    if (!viewPreview && !showPrompt) return;

    // Navigate to preview page
    navigate("/mina-sidor/redigera-annons/forhandsvy", {
      state: { prevRoute: location.pathname },
    });
    // eslint-disable-next-line
  }, [viewPreview]);

  const adPreviewHandler = () => {
    // Disable prompt
    setShowPrompt(false);

    // Set the AdPreviewInfo (context) to use on preview page
    setAdPreviewInfo((prev) => ({
      ...prev,
      info: formData,
      images: newImages,
    }));

    // Navigate to preview page
    setViewPreview(true);
  };

  const validateField = (e) => {
    const { value, name } = e.target;
    const defaultDropdownValues = ["Välj kommun", "Typ av lokal"];
    const invalidDropdownValue = defaultDropdownValues.includes(value);

    if (!value || invalidDropdownValue || (name === "zip_code" && value.length !== 5)) {
      setInvalidFields([...invalidFields, name]);
    } else {
      setInvalidFields(invalidFields.filter((field) => field !== name));
    }
  };

  const handleImageSelect = (index) => {
    if (index >= formData?.images?.length) {
      setImageError("Vänligen spara ändringar innan du sorterar bilderna.");
      setSelectedImageIndex((prevIndex) => (prevIndex === index ? null : index));
      return;
    }
    setImageError(null);
    setSelectedImageIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const moveImage = (index, direction) => {
    if (selectedImageIndex === null) return;

    const images = [...formData.images];

    if (direction === "left" && index > 0) {
      const [movedImage] = images.splice(index, 1);
      images.splice(index - 1, 0, movedImage);
      setSelectedImageIndex(selectedImageIndex - 1);
    } else if (direction === "right" && index < images.length - 1) {
      const [movedImage] = images.splice(index, 1);
      images.splice(index + 1, 0, movedImage);
      setSelectedImageIndex(selectedImageIndex + 1);
    } else {
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      images,
    }));
  };

  return (
    pageHeading && ( // Only show once listing data is fetched
      <>
        <Helmet>
          <title>Redigera annons | Vakansa</title>
          <meta
            name="description"
            content="Redigera och publicera din annons enkelt på Vakansa för att nå potentiella hyresgäster."
          />
          <link rel="canonical" href={backend(`/mina-sidor/annonser/redigera-annons/${formData._id}`)} />
          <meta property="og:image" content="/images/logo/meta-logo.jpg" />
          <meta property="og:title" content="Redigera en annons på Vakansa" />
          <meta
            property="og:description"
            content="Redigera och publicera din annons enkelt på Vakansa för att nå potentiella hyresgäster."
          />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="edit-ad-container">
          <h2>{pageHeading}</h2>
          <span className="create-ad-inline-msg">(Fält markerade med * är obligatoriska och måste fyllas i)</span>
          <br />
          <br />
          <br />
          <form>
            <div className="edit-ad-input-row">
              <div className="edit-ad-input-col edit-ad-input-col-lg">
                <label htmlFor="editAdOwner">
                  Fastighetsägare*{" "}
                  <span className="edit-ad-information-italics">(Detta fält publiceras inte i annonsen)</span>
                  {invalidFields.includes("owner_name") && (
                    <span className="edit-ad-error-msg">
                      Ange en giltig fastighetsägare{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  className="edit-ad-input-fastighet"
                  type="text"
                  id="editAdAddress"
                  name="owner_name"
                  placeholder="Fastighetsägare"
                  value={formData?.owner_name}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
            </div>
            <div className="edit-ad-input-row">
              <div className="edit-ad-input-col edit-ad-input-col-lg">
                <label htmlFor="editAdAddress">
                  Adress*
                  {invalidFields.includes("address") && (
                    <span className="edit-ad-error-msg">
                      Ange en giltig adress{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  id="editAdAddress"
                  name="address"
                  placeholder="Adress"
                  value={formData?.address}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdCity">
                  Stad*
                  {invalidFields.includes("city") && (
                    <span className="edit-ad-error-msg">
                      Ange stad{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  id="editAdCity"
                  name="city"
                  placeholder="Stad"
                  value={formData?.city}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdMunicipality">
                  Kommun*
                  {invalidFields.includes("municipality") && (
                    <span className="edit-ad-error-msg">
                      Ange kommun{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <div className="edit-ad-select-wrapper">
                  <select
                    id="editAdMunicipality"
                    name="municipality"
                    // defaultValue="Välj kommun"
                    value={formData?.municipality}
                    onChange={onChangeHandler}
                    onBlur={validateField}
                  >
                    <option hidden>Välj kommun</option>
                    <option>Stockholm </option>
                    <option>Göteborg </option>
                    <option>Malmö </option>
                    <option>Uppsala</option>
                    <optgroup label="Alla kommuner">
                      {municipalities.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                  <img src="/images/icons/arrow-down.svg" alt="arrow-down" />
                </div>
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-sm">
                <label htmlFor="editAdZip">
                  Postkod*
                  {invalidFields.includes("zip_code") && (
                    <span className="edit-ad-error-msg">
                      Ange en giltig postkod (5 tecken){" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  id="editAdZip"
                  name="zip_code"
                  placeholder="Postkod"
                  value={formData?.zip_code}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
            </div>
            <div className="edit-ad-input-row">
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdType">
                  Lokal*
                  {invalidFields.includes("type_of_room") && (
                    <span className="edit-ad-error-msg">
                      Ange lokaltyp{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <div className="edit-ad-select-wrapper edit-ad-input-col-md">
                  <select
                    id="editAdType"
                    name="type_of_room"
                    value={formData?.type_of_room}
                    onChange={onChangeHandler}
                    onBlur={validateField}
                  >
                    <option hidden>Typ av lokal</option>
                    {roomTypes.map((roomType, i) => (
                      <option key={i}>{roomType}</option>
                    ))}
                  </select>
                  <img src="/images/icons/arrow-down.svg" alt="arrow-down" />
                </div>
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-sm">
                <label htmlFor="editAdArea">
                  Yta*
                  {invalidFields.includes("area") && (
                    <span className="edit-ad-error-msg">
                      Ange yta{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </label>
                <input
                  id="editAdArea"
                  name="area"
                  type="text"
                  inputMode="numeric"
                  placeholder="kvm"
                  value={formData?.area}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdMaxPeople">Lämpligt för antal personer</label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="editAdMaxPeople"
                  name="capacity"
                  placeholder="st"
                  value={formData?.capacity}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <label htmlFor="editAdWorkspaces">Antal sittplatser</label>
                <input
                  type="text"
                  inputMode="numeric"
                  id="editAdWorkspaces"
                  name="workspaces"
                  placeholder="st"
                  value={formData?.workspaces}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="edit-ad-input-col edit-ad-input-col-md">
                <div className="edit-ad-vat-tax-wrapper">
                  <div className="radio-group">
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="true"
                        className="shit-radio-button"
                        checked={formData?.vat_tax_required === true}
                        onChange={() =>
                          setFormData((prev) => ({
                            ...prev,
                            vat_tax_required: true,
                          }))
                        }
                      />
                      <span className="custom-radio-button" />
                      &nbsp; Momsad
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="false"
                        className="shit-radio-button"
                        checked={formData?.vat_tax_required === false}
                        onChange={() =>
                          setFormData((prev) => ({
                            ...prev,
                            vat_tax_required: false,
                          }))
                        }
                      />
                      <span className="custom-radio-button" />
                      &nbsp; Icke momsad
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <FacilitiesForm formData={formData} setFormData={setFormData} />
            <div className="edit-ad-input-row">
              <div className="edit-ad-input-col edit-ad-input-col-xxl">
                <h5 htmlFor="editAdDescription">
                  Beskrivning av lokalen*
                  {invalidFields.includes("description") && (
                    <span className="edit-ad-error-msg">
                      Ange beskrivning{" "}
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </span>
                  )}
                </h5>
                <p>
                  Lyft här information som inte inkluderas här ovan, tex hur man tar sig till lokalen, vad som gör den
                  unik och vilka aktiviteter som inte kan göras här.
                </p>
                <p>
                  <i>
                    Vänligen ange på ett ungefär vilka dagar och tider lokalen ska hyras ut, eller om den är helt
                    vakant.
                  </i>
                </p>
                <p>
                  Det är inte tillåtet att uppge kontaktuppgifter eller andra ledande uppgifter i beskrivningstexten som
                  skulle göra att ni kringgår Vakansas tjänster och våra användarvillkor
                </p>
                <textarea
                  id="editAdDescription"
                  name="description"
                  className="form-control"
                  placeholder="Skriv här..."
                  value={formData?.description}
                  onChange={onChangeHandler}
                  onBlur={validateField}
                />
              </div>
            </div>
            <div>
              <h5>Lägg till bilder</h5>
              <p>
                Ladda upp minst <strong>4 bilder</strong> för att ge en bra presentation av lokalen. Du kan bifoga upp
                till <strong>12 bilder</strong>.
              </p>
              <p>För bästa resultat rekommenderar vi att du inkluderar en planritning av lokalen.</p>
              <p>
                <b>Maximal filstorlek per bild:</b> 10 MB. Vi stödjer följande format:{" "}
                <strong>JPG, PNG, WEBP och GIF</strong>.
              </p>
              <p className="edit-ad-image-italics">
                Den första bilden du laddar upp blir annonsens huvudbild (visas längst till vänster).
              </p>

              {ImageTooBig !== null && <h3 style={{ color: "red", fontWeight: "500" }}>{ImageTooBig}</h3>}
              <div className="edit-ad-image-container">
                {/* Images fetched from Cloudinary */}
                {formData?.images &&
                  formData?.images.map((image, i) => (
                    <div
                      key={`fetched-${i}`}
                      className={`edit-ad-image ${selectedImageIndex === i ? "selected" : ""}`}
                      onClick={() => handleImageSelect(i)}
                    >
                      <Image
                        key={`fetched-${i}`}
                        cloudName="vakansa"
                        publicId={`listings/${formData?._id}/${image}`}
                        dpr="auto"
                        className="edit-ad-fetched-image"
                      >
                        <Transformation crop="fill" gravity="center" />
                      </Image>
                      <button
                        type="button"
                        onClick={() => removeOldImageHandler(image)}
                        className="edit-ad-image-delete-button"
                      >
                        <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                          <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                        </svg>
                      </button>
                    </div>
                  ))}
                {/* Local images added by the user */}
                {newImages.map((image, i) => (
                  <div
                    key={`local-${i}`}
                    className={`edit-ad-image ${
                      selectedImageIndex === formData?.images?.length + i ? "selected-local" : ""
                    }`}
                    onClick={() => handleImageSelect(formData?.images?.length + i)}
                  >
                    <img src={image.url} alt="" className="edit-ad-added-image" />
                    <button
                      type="button"
                      onClick={() => removeNewImageHandler(image.id)}
                      className="edit-ad-image-delete-button"
                    >
                      <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                      </svg>
                    </button>
                  </div>
                ))}
                {formData?.images.length + newImages.length < 12 && (
                  <>
                    <label className="edit-ad-custom-file-input" htmlFor="edit-ad-image-input">
                      <img src="/images/icons/plus-circle.svg" alt="plus with a circle" />
                      <span>Välj bild</span>
                    </label>
                    <input
                      type="file"
                      name="images"
                      id="edit-ad-image-input"
                      multiple
                      accept="image/*"
                      onChange={onImagesChange}
                    />
                  </>
                )}
              </div>
              {imageError && <div className="edit-ad-image-error-msg">{imageError}</div>}
              <div className="edit-ad-btn-wrapper-image-change-order">
                <RoundedButton
                  onClick={(e) => {
                    e.preventDefault();
                    moveImage(selectedImageIndex, "left");
                  }}
                  disabled={
                    selectedImageIndex === 0 ||
                    selectedImageIndex === null ||
                    selectedImageIndex >= formData?.images?.length
                  }
                  size="xs"
                  label={"<"}
                />
                <RoundedButton
                  onClick={(e) => {
                    e.preventDefault();
                    moveImage(selectedImageIndex, "right");
                  }}
                  disabled={
                    selectedImageIndex === formData.images.length - 1 ||
                    selectedImageIndex === null ||
                    selectedImageIndex >= formData?.images?.length
                  }
                  size="xs"
                  label={">"}
                />
              </div>
              <h5>Ange pris*</h5>
              <p>
                (fyll i minst 1 fält)
                {invalidFields.includes("rent") && (
                  <span className="edit-ad-error-msg">
                    Ange åtminstone 1 pris{" "}
                    <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                    </svg>
                  </span>
                )}
              </p>
              <div className="edit-ad-input-price-row">
                <div className="edit-ad-input-col edit-ad-input-col-md">
                  <label htmlFor="editAdMinPrice">Timpris (enstaka timmar)</label>
                  <input
                    type="text"
                    inputMode="numeric"
                    name="hourly_rent"
                    id="editAdMinPrice"
                    placeholder="kr/timme"
                    value={formData?.hourly_rent}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="edit-ad-input-col edit-ad-input-col-md">
                  <label htmlFor="editAdMaxPrice">Dagspris (8 timmar i rad)</label>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="editAdMaxPrice"
                    name="daily_rent"
                    placeholder="kr/dag"
                    value={formData?.daily_rent}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="edit-ad-input-col edit-ad-input-col-md">
                  <label htmlFor="editAdMaxPrice">Månadspris (30 dagar i rad)</label>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="editAdMaxPrice"
                    name="monthly_rent"
                    placeholder="kr/månad"
                    value={formData?.monthly_rent}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="edit-ad-input-col edit-ad-input-col-md">
                  <label htmlFor="editAdMaxPrice">Pris per arbetsplats (per månad)</label>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="editAdMaxPrice"
                    name="workspace_rent"
                    placeholder="kr/arbetsplats"
                    value={formData?.workspace_rent}
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="edit-ad-form-customer-info">
              <p>
                Kom ihåg att Vakansa tar 15% av hyresintäkterna vid lyckad uthyrning av er lokal. Läs mer om vår
                prismodell {""}
                <a target="_blank" rel="noreferrer" href="/hyr-ut-lokal">
                  här.
                </a>
              </p>
            </div>
            {disableSubmit && (
              <p className="edit-ad-form-buttons-disabled-info">
                <svg viewBox="0 0 24 24" fill="currentColor" height="2rem" {...props}>
                  <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm3.7 12.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L12 13.4l-2.3 2.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.3-2.3-2.3-2.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3 2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l2.3 2.3z" />
                </svg>{" "}
                *Alla obligatoriska fält måste vara ifyllda för att kunna gå vidare
              </p>
            )}
            <div className="edit-ad-button-row">
              <button type="button" onClick={adPreviewHandler} disabled={disableSubmit}>
                Förhandsvy
              </button>
              {formData?.active ? (
                <button type="button" onClick={() => saveAdHandler("inactivate")}>
                  Inaktivera annons
                </button>
              ) : (
                <button type="button" onClick={() => saveAdHandler("activate")}>
                  Aktivera annons
                </button>
              )}
              <button type="button" onClick={saveAdHandler}>
                Spara ändringar
              </button>
            </div>
          </form>
          <ReactTooltip className="tooltip" />
        </div>
        {/* Spinner while ad is being created/updated */}
        {loading && <Loading text="Sparar ändringar..." />}
        {showErrorModal && (
          <ConfirmationModal
            heading="Någonting gick fel..."
            text="Ett problem uppstod med när dina ändringar sparades. Vänligen prova igen senare."
            setShowModal={setShowErrorModal}
            error
          />
        )}
        {showSaveChangesModal && (
          <ConfirmationModal
            heading="Dina ändringar har sparats"
            text='Gå till "mina annonser" under Mina annonser för att fortsätta redigera annonsen.'
            linkTo="/mina-sidor/annonser"
            setShowModal={setShowSaveChangesModal}
            note={errorMessage ? errorMessage : ""}
          />
        )}
        {showWarningModal && (
          <WarningModal
            heading="Lämna utan att spara"
            text="Är du säker att du vill lämna sidan? Dina ändringar kommer ej att sparas."
            confirmBtnText="Lämna"
            onCancel={cancelNavigation}
            onConfirm={() => {
              setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
              confirmNavigation();
            }}
          />
        )}
      </>
    )
  );
}

export default EditAd;
