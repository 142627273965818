import "./momsDropdown.css";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import CloseButton from "../../../../components/Buttons/CloseButton/closeButton";

export default function MomsDropdown({ selectedMoms, setSelectedMoms, closeMomsDropdown }) {
  const handleCheckboxChange = (value) => {
    if (selectedMoms.includes(value)) {
      setSelectedMoms(selectedMoms.filter((item) => item !== value));
    } else {
      setSelectedMoms([...selectedMoms, value]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedMoms([]);
    sessionStorage.removeItem("selectedMoms");
  };

  return (
    <div className={`moms-dropdown`}>
      <div className="space-dropdown-header">
        <p>Momsad lokal?</p>
        <CloseButton onClick={closeMomsDropdown} />
      </div>
      <div className="moms-content-wrapper">
        <div className="moms-checkbox-wrapper">
          <Checkbox
            label="Momsad"
            isChecked={selectedMoms.includes("true")}
            onChange={() => handleCheckboxChange("true")}
          />
          <Checkbox
            label="Ej momsad"
            isChecked={selectedMoms.includes("false")}
            onChange={() => handleCheckboxChange("false")}
          />
        </div>
      </div>
      <hr />
      <div className="moms-button-wrapper">
        <TextButton label="Rensa" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}
