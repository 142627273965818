import React from "react";
import { vakansaFAQItems } from "./data";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import "./vakansaFAQ.css";
import Accordion from "../../../components/Accordion/Accordion";
import SEOTags from "../../../utils/SEOTags";

const VakansaFAQ = () => {
  return (
    <>
      <SEOTags
        title={"Vad är Vakansa?   "}
        description={
          "Vakansa är en lokaldelningsplattform som underlättar för hyresgäster att börja dela lokaler med andra och göra det billigare för alla!"
        }
        canonicalUrl={"/fragor-och-svar/vad-ar-vakansa"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="accordion-wrapper">
        <h1>Vad är Vakansa?</h1>
        <p className="under-title">Vanliga frågor om vad Vakansa gör</p>
        <Accordion items={vakansaFAQItems} />
        <div className="contact-back-button-wrapper">
          <p className="contact-info">
            För övriga frågor eller funderingar är du välkommen att kontakta oss på e-postadressen:{" "}
            <a href="mailto:info@vakansa.se">info@vakansa.se</a> eller telefon nummer:{" "}
            <a href="tel:+46767065662">Telefon: 076-706 56 62</a>
            <br />
            <br />
          </p>
          <br />
          <RoundedLinkButton label="Tillbaka" size="md" color="white" href="/fragor-och-svar" />
        </div>
      </div>
      <main className="faq-info-wrapper">
        <h1>Vad är Vakansa?</h1>
        <p className="under-title">Vanliga frågor om vad Vakansa gör</p>
        {vakansaFAQItems.map((item, index) => (
          <div key={index}>
            <h2>{item.title}</h2>
            <p>{item.content}</p>
          </div>
        ))}
        <br />
        <br />
        <p>
          För övriga frågor eller funderingar är du välkommen att kontakta oss på e-postadressen:{" "}
          <a href="mailto:info@vakansa.se">info@vakansa.se</a> eller telefon nummer:{" "}
          <a href="tel:+46767065662">Telefon: 076-706 56 62</a>
          <br />
          <br />
        </p>
        <br />
        <RoundedLinkButton
          className="button-faq-you"
          label="Tillbaka"
          size="md"
          color="white"
          href={"/fragor-och-svar"}
        />
      </main>
    </>
  );
};

export default VakansaFAQ;
