import React from "react";
import { Helmet } from "react-helmet-async";
import { backend } from "../backend";

const SEOTags = ({ title, description, canonicalUrl, ogImage, structuredData = {} }) => {
  const defaultStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Vakansa",
    url: backend("/"),
    description:
      "Hyr ut och hyr lokaler i andrahand som kontor, samlingslokaler, biografer, idrottshallar, konferenslokaler och annat via Vakansa",
    inLanguage: "sv-SE",
    sameAs: [
      "https://www.facebook.com/Vakansa/",
      "https://www.instagram.com/vakansa.se/",
      "https://www.linkedin.com/company/vakansa/",
    ],
  };

  const mergedStructuredData =
    structuredData["@type"] === "Product"
      ? { ...defaultStructuredData, ...structuredData, inLanguage: undefined }
      : { ...defaultStructuredData, ...structuredData };

  return (
    <Helmet>
      <title>{title}</title>

      {/* Regular Meta Tags */}
      {description && <meta name="description" content={description} />}

      {/* Open Graph Meta Tags */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:type" content={"website"} />
      <meta property="og:site" content={backend(canonicalUrl)} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content={"summary"} />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {ogImage && <meta name="twitter:image" content={ogImage} />}

      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={backend(canonicalUrl)} />}

      {/* Structured Data */}
      {mergedStructuredData && <script type="application/ld+json">{JSON.stringify(mergedStructuredData)}</script>}
    </Helmet>
  );
};

export default SEOTags;
