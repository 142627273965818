import React from "react";

export const renterFAQItems = [
  {
    title: "Kostar det något att registrera sig på plattformen?",
    content: (
      <>
        För dig som hyresgäst så kostar det ingenting att söka lokal på Vakansa och du behöver inte registrera dig!
        <br />
        <br />
        Du kan filtrera fram lokaler på <a href="/lediga-lokaler">Lediga Lokaler</a> utifrån dina exakta preferenser och
        skicka förfrågan till våra hyresvärdar hur mycket du vill - så länge du inte spammar och/eller inte svarar på
        våra kontrollfrågor. För att säkerställa att de hyresgäster och hyresvärdar som får kontakt med varandra via
        Vakansa ska få den bästa upplevelsen hör vi av oss regelbundet för att se hur det går. Om du underlåter dig att
        svara kommer vi debitera dig en straffavgift.
      </>
    ),
  },
  {
    title: "Hur gör jag för att hyra en lokal via Vakansa?",
    content: (
      <>
        När du hittat en lokal du vill hyra klickar du på “Skicka förfrågan” knappen på annonsen där du kan meddela vad
        du avser göra i lokalen, tid och dag du önskar hyra lokalen, samt andra önskemål till hyresvärden. Därefter
        återkommer vi med eventuella frågor och ordnar med en visning av lokalen om lokalen är tillgänglig när ni önskar
        använda den. Om allt ser bra ut upprättas ett kontrakt mellan er och hyresvärden och du får tillträde till
        lokalen.
      </>
    ),
  },
  {
    title: "Vad kostar det att använda Vakansa?",
    content: (
      <>
        För dig som hyresgäst kostar det inget att hyra en lokal via Vakansa utan det är hyresvärden som betalar en
        förmedlingsavgift till oss på Vakansa som betalning för vår tjänst.
      </>
    ),
  },
  {
    title: "Hur går uthyrningsprocessen till?",
    content: (
      <>
        När du skickat en förfrågan på en lokal via <a href="/">Vakansa</a> skickas ett "matchningsmejl" till dig,
        hyresvärden och Vakansa. Detta representerar förmedlingen. Det är via detta matchningsmejl som Vakansa följer
        uthyrningsprocessen. Ni får i matchningsmejlet information om varandras kontaktuppgifter för att kunna ta
        samtalet vidare. Här avgör hyresvärden om den vill ta kontakt med er för en visning eller avböja uthyrning. När
        en visning väl genomförts och ni kommit överens om en förhyrning så ordnar ni själva med avtal och allt det
        praktiska för att gå i mål med uthyrningsprocessen. Oavsett om en uthyrning blir av eller inte är det ert ansvar
        att informera oss på Vakansa om hur det går.
        <br />
        <br />
        OBS: För att vi på Vakansa ska kunna upprätthålla vårt ansvar åtar Ni er som användare av våra tjänster, att
        besvara våra kontrollfrågor om hur uthyrningsprocessen med förmedlad part fortskrider. Om du som Hyresvärd
        underlåter dig att svara, efter att vi gjort våra tre (3) kontaktförsök, utgår en straffavgift. Om du som
        Hyresgäst underlåter dig att svara, efter att vi gjort våra tre (3) kontaktförsök, kommer en straffavgift om 500
        kr debiteras din organisation. Detta i enlighet med våra användarvillkor.
      </>
    ),
  },
  {
    title: "Kan ni hjälpa till med att skriva kontrakt parterna emellan?",
    content: (
      <>
        Vakansa har två prismodeller, “Vakansa Bas” och “Vakansa Mest”, varav “Vakansa Mest” omfattar juridisk hjälp med
        att upprätta kontrakt parterna emellan oavsett om lokalen är en momsad lokal eller icke momsad lokal.
      </>
    ),
  },
  {
    title: "Hanterar eller begär Vakansa depositioner?",
    content: (
      <>
        Vakansa tar inte och hanterar inga depositioner via vår plattform, men kan vara behjälplig i frågor som rör
        depositioner. Vi rekommenderar alltid Hyresvärdar att ta en deposition i syfte att ha en säkerhet vid eventuella
        framtida risker och ta betalt i förskott. Detta är det sedvanliga sättet att göra det på som Hyresgäst.
        Depositionen brukar ligga på mellan 1-6 månadshyror för längre uthyrningar eller en kännbar summa vid kortare
        uthyrningar, beroende på lokalens storlek och ändamål.
        <br />
        <br />
        Om du inte vill betala en deposition och du får krav på det - hör av dig så kan vi hjälpa dig med en
        depositionspremie istället! En försäkring som innebär att du inte behöver ligga ute med pengar, men fortfarande
        garanterar det skydd hyresvärden är ute efter.
      </>
    ),
  },
  {
    title: "Är lokalerna via Vakansa försäkrade?",
    content: (
      <>
        Vakansa ansvarar inte för skador som uppkommer i lokaler, på fönster, skyltfönster, dörrar eller portar som hör
        till lokaler eller som finns i direkt anslutning till lokaler. Detta gäller oavsett vem eller vad som orsakat
        skadan. Varje Hyresvärd och Hyresgäst ansvarar för sitt eget försäkringsbehov och vi rekommenderar att man
        åtminstone ska ha en verksamhetsförsäkring.
      </>
    ),
  },
  {
    title: "Tar ni någon kreditupplysning på mig som hyresgäst?",
    content: (
      <>
        Vi har som krav på Vakansa att alla hyresgäster måste ange organisationsnummer för att kunna skicka en förfrågan
        på lokal. Detta ger hyresvärden möjlighet att göra en kreditupplysning själva.
      </>
    ),
  },
  {
    title: "Vad förbinder jag mig till när jag använder Vakansa?",
    content: (
      <>
        Du förbinder dig att följa våra <a href="/anvandarvillkor">Användarvillkor.</a>
      </>
    ),
  },
];
