import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { backend } from "../../backend";
import { logAxiosError } from "../../logging/error";
import SEOTags from "../../utils/SEOTags";

// Components
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs";
import Spinner from "../../components/Spinner/Spinner";
// Sections
import Carousel from "./sections/Carousel/Carousel";
import Heading from "./sections/Heading/Heading";
import Info from "./sections/Info/Info";
import Description from "./sections/Description/Description";
import Facilities from "./sections/Facilities/Facilities";
import BookingPanel from "./BookingPanel/BookingPanel";

import { TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { MapContainerWithGestureHandling } from "../../components/MapContainerGesture/MapContainerWithGestureHandling";
import IconV from "../../assets/icons/vakansa-map-pin.png";
import IconVShadow from "../../assets/icons/vakansa-map-pin-shadow.png";
import "leaflet/dist/leaflet.css";
import { ReactComponent as MapPin } from "../../assets/icons/map-pin.svg";
import TextButton from "../../components/Buttons/TextButton/TextButton";
import "./ad.css";
import { createSEOUrl } from "../../utils/createSEOUrl";
function Ad() {
  const [ad, setAd] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const isMobile = window.innerWidth < 980;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  const redirect404 = () => {
    navigate("/kunde-ej-hitta-annons", { replace: true });
  };

  const customIcon = L.icon({
    iconUrl: IconV,
    shadowUrl: IconVShadow,
    iconSize: [38, 37],
    iconAnchor: [28, 40],
    shadowSize: [38, 43],
    shadowAnchor: [17, 46],
  });

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const { data } = await axios.get(backend("/api/listings/get-by-id"), {
          params: {
            token: localStorage.getItem("token"),
            id: id,
          },
        });
        if (data.status) {
          setAd(data.ad);
        } else {
          redirect404(); // Redirect to 404 page if ad not found
        }
      } catch (error) {
        logAxiosError(error);
        redirect404(); // Redirect to 404 page if there's an error
      } finally {
        setIsLoading(false);
      }
    };

    fetchAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) {
    return (
      <div className="ad-page">
        <Spinner />
      </div>
    );
  }

  const getCorrectFormOfLedig = (typeOfRoom) => {
    const neuterTypes = [
      "Ateljé",
      "Behandlingsrum",
      "Klassrum",
      "Kontorshotell/Co-working",
      "Lager/Logistik",
      "Matsal/Kök",
      "Musiksal",
      "Pop-up",
      "Studio",
      "Övrigt",
    ];

    return neuterTypes.includes(typeOfRoom) ? "Ledigt" : "Ledig";
  };

  const correctFormOfLedig = getCorrectFormOfLedig(ad.type_of_room);

  const description = [
    `${correctFormOfLedig} ${ad.type_of_room.toLowerCase()} finns att hyra i ${ad.city} på ${ad.address} i ${
      ad.municipality
    } på ${ad.area}kvm för`,
    ad.hourly_rent ? ` ${ad.hourly_rent} kr/timme` : "",
    ad.daily_rent ? ` ${ad.daily_rent} kr/dag` : "",
    ad.monthly_rent ? ` ${ad.monthly_rent} kr/månad` : "",
    ad.workspace_rent ? ` ${ad.workspace_rent} kr/månad per arbetsplats` : "",
    "  ",
  ]
    .filter(Boolean)
    .join(" ");

  const canonicalUrl = `/lediga-lokaler/${createSEOUrl(ad.municipality)}/${createSEOUrl(
    ad.type_of_room
  )}/${createSEOUrl(ad.address)}/${ad._id}`;

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: `Advertisement for ${ad.type_of_room} at ${ad.address}`,
    description: description,
    image: [
      `https://res.cloudinary.com/vakansa/image/upload/f_auto,q_60/c_scale,dpr_auto,h_360,w_auto/v1/listings/${ad._id}/${ad.images[0]}`,
    ],
    url: backend(
      `/lediga-lokaler/${createSEOUrl(ad.municipality)}/${createSEOUrl(ad.type_of_room)}/${createSEOUrl(ad.address)}/${
        ad._id
      }`
    ),
    offers: {
      "@type": "Offer",
      priceCurrency: "SEK",
      price: ad.hourly_rent || ad.daily_rent || ad.monthly_rent,
      priceSpecification: {
        "@type": "UnitPriceSpecification",
        price: ad.hourly_rent || ad.daily_rent || ad.monthly_rent,
        priceCurrency: "SEK",
        unitText: ad.hourly_rent ? "HUR" : ad.daily_rent ? "DAY" : "MON",
      },
      itemOffered: {
        "@type": "Room",
        name: ad.type_of_room,
        address: {
          "@type": "PostalAddress",
          streetAddress: ad.address,
          addressLocality: ad.municipality,
          addressRegion: "SE",
          postalCode: ad.zip_code,
          addressCountry: "SE",
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: ad.location.coordinates[1],
          longitude: ad.location.coordinates[0],
        },
      },
      url: backend(
        `/lediga-lokaler/${createSEOUrl(ad.municipality)}/${createSEOUrl(ad.type_of_room)}/${createSEOUrl(
          ad.address
        )}/${ad._id}`
      ),
    },
  };

  return (
    <div className="ad-page">
      <SEOTags
        title={(() => {
          // Create the base title
          let baseTitle =
            "Ledig " +
            ad.type_of_room.toLowerCase() +
            " i " +
            ad.municipality +
            " på " +
            ad.address +
            ", " +
            ad.area +
            "kvm";
          if (baseTitle.length < 46) {
            baseTitle += " finns att hyra";
          }
          return baseTitle.length <= 60 ? baseTitle : baseTitle.slice(0, 60);
        })()}
        description={description}
        canonicalUrl={canonicalUrl}
        ogImage={`https://res.cloudinary.com/vakansa/image/upload/f_auto,q_60/c_scale,dpr_auto,h_360,w_auto/v1/listings/${ad._id}/${ad.images[0]}`}
        structuredData={jsonLdSchema}
      />

      <Breadcrumbs ad={ad} />

      {isMobile === true ? (
        <>
          <div id="ad-page-body">
            {ad ? (
              <>
                <Heading className="ad-page-heading" ad={ad} />
                <Carousel ad={ad} />
                <div id="ad-page-info">
                  <hr />
                  <Info ad={ad} />
                  <hr />
                  <BookingPanel ad={ad} />
                  <div className="heading-request">
                    <p>
                      Passade inte denna lokal?{" "}
                      <TextButton label={"Klicka här"} color={"green"} size={"xxs"} href="/lokalforslag" /> så hjälper
                      vi dig att hitta en bättre, kostnadsfritt!
                    </p>
                  </div>
                  <hr />
                  <Description ad={ad} />
                  <hr />
                  <Facilities ad={ad} />
                  <hr />
                  <p className="ad-address">
                    <MapPin />
                    {ad.address}, {ad.city}
                  </p>
                  <div className="ad-map-slim">
                    <MapContainerWithGestureHandling
                      center={[ad.location.coordinates[1], ad.location.coordinates[0]]}
                      zoom={20}
                      style={{ height: "100vh" }}
                    >
                      <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                        attribution='&copy; <a href="https://carto.com/">CARTO</a> contributors'
                      />
                      <Marker position={[ad.location.coordinates[1], ad.location.coordinates[0]]} icon={customIcon} />
                    </MapContainerWithGestureHandling>
                  </div>
                </div>
              </>
            ) : (
              <p>Ad not found</p>
            )}
          </div>
        </>
      ) : (
        <>
          {isMobile === false ? (
            <>
              <div id="ad-page-body">
                {ad ? (
                  <>
                    <Heading className="ad-page-heading" ad={ad} />
                    <div className="ad-page-carousel-top-wrapper">
                      <Carousel ad={ad} />
                      <BookingPanel ad={ad} />
                    </div>
                    <div id="ad-page-info">
                      <div className="heading-request">
                        <p>
                          Passade inte denna lokal?{" "}
                          <TextButton label={"Klicka här"} color={"green"} size={"xxs"} href="/lokalforslag" /> så
                          hjälper vi dig att hitta en bättre, kostnadsfritt!
                        </p>
                      </div>

                      <hr />
                      <Info ad={ad} />
                      <hr />
                      <Description ad={ad} />
                      <hr />
                      <Facilities ad={ad} />
                      <hr />
                      <p className="ad-address">
                        <MapPin />
                        {ad.address}, {ad.city}
                      </p>
                      <div className="ad-map-slim">
                        <MapContainerWithGestureHandling
                          center={[ad.location.coordinates[1], ad.location.coordinates[0]]}
                          zoom={20}
                          style={{ height: "100vh" }}
                        >
                          <TileLayer
                            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                            attribution='&copy; <a href="https://carto.com/">CARTO</a> contributors'
                          />
                          <Marker
                            position={[ad.location.coordinates[1], ad.location.coordinates[0]]}
                            icon={customIcon}
                          />
                        </MapContainerWithGestureHandling>
                        <div></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Ad not found</p>
                )}
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

export default Ad;
