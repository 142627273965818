import React, { useState } from "react";
import axios from "axios";
import { backend } from "../../backend";
import { logAxiosError } from "../../logging/error";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal";
import SEOTags from "../../utils/SEOTags";

const Register = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [serverError, setServerError] = useState("");

  const handleFormSubmit = async (formData) => {
    try {
      const { data } = await axios.post(backend("/api/users/create"), {
        user: formData,
      });

      if (data.status) {
        setShowConfirmationModal(true);
      }
    } catch (error) {
      logAxiosError(error);
      setServerError(error.response?.data?.msg);
    }
  };

  return (
    <>
      <SEOTags
        title={"Registrera ett konto & börja annonsera era lokaler gratis!"}
        description={
          "Registrera ett konto på Vakansa för att skapa & publicera annonser på era lokaler idag! Det är gratis att annonsera på vår uthyrningsplattform!"
        }
        canonicalUrl={"/registrera-konto"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <RegisterForm serverError={serverError} onFormSubmit={handleFormSubmit} />
      {showConfirmationModal && <ConfirmationModal setShowConfirmationModal={setShowConfirmationModal} />}
    </>
  );
};

export default Register;
