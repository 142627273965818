import React, { useState, useEffect } from "react";
import TeamCard from "./TeamCard/TeamCard";
import TextButton from "../../components/Buttons/TextButton/TextButton";
import CollaborationsSection from "./CollaborationsSection/CollaborationsSection";
import useCheckIfMobile from "../../customHooks/useCheckIfMobile";
import RobinImage from "../../assets/team/robin.png";
import VisionImage from "../../assets/illustrations/VISION.png";
import "./aboutUsPage.css";
import SEOTags from "../../utils/SEOTags";
import tedX from "../../assets/videos/tedx.mp4";

function AboutUs() {
  const isMobile = useCheckIfMobile();
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <div className="about-us-outer">
      <SEOTags
        title={"Välkommen att läsa mer om oss på Vakansa & varför vi finns!"}
        description={
          "Vakansa grundades med den enkla övertygelsen att det finns tillräckligt många byggnader & lokaler för alla samhällets aktörer - genom delning!"
        }
        canonicalUrl={"/om-oss"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="about-us-inner">
        <main className={`about-us-container`}>
          <div className={`about-us-intro-content`}>
            <div className={`about-us-intro-text`}>
              <h1>Om oss</h1>
              <h2>Staden är till för människor</h2>
              <p>
                Vakansa startades med den enkla övertygelsen att det redan finns tillräckligt många byggnader och
                lokaler för alla samhällets aktörer. Vi ser hur byggnader och lokaler står helt eller delvis tomma
                överallt i våra städer. Samtidigt stängs många lokalsökande idag ute från lokalmarknaden på grund av
                dyra hyror och krångliga regelverk.
              </p>
              {isMobile && isClicked === false ? (
                <button className={"about-us-readmore-button"} onClick={handleClick}>
                  Läs mer
                </button>
              ) : (
                <>
                  <br />
                  <p>
                    Innan pandemin så gjordes studier av kontor där man undersökte hur mycket dessa lokaltyper faktiskt
                    används (Vill du veta mer, kan du läsa hela rapporten ”
                    <a
                      href="https://www.iva.se/contentassets/ab0dbb9ad4a047058cf447814a3a3b5e/201912-iva-rece-branschrapport-lokaler.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Resurseffektiva lokaler i Sverige
                    </a>
                    ”). Då kom man fram till att kontor nyttjas så lite som 10% av tiden. Det innebär att hyresgäster
                    idag betalar 100% av hyran för lokaler som kan stå tomma så mycket som 90% av tiden!
                  </p>
                  <br />
                  <p>
                    Så behöver det inte vara! Alla byggnader och lokaler ska kunna användas till en mångfald av
                    aktiviteter som bjuder in en mångfald av individer.
                  </p>
                  <p>
                    Vi på Vakansa vill synliggöra och tillgängliggöra när lokaler står tomma så att fler kan använda
                    dem. För lokaler ska användas, inte stå tomma!
                  </p>
                  {isMobile && isClicked === true ? (
                    <button className={`about-us-readmore-button`} onClick={handleClick}>
                      Visa mindre
                    </button>
                  ) : null}
                </>
              )}
            </div>
            <div className={`about-us-intro-video`}>
              <div className="video-card-container">
                <video controls width="100%" height="auto">
                  <source src={tedX} type="video/mp4" />
                  your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div className={`vision-section`}>
            <div className="about-us-vision-image">
              <img src={VisionImage} alt="Ett livligt samhälle" />
            </div>
            <div className="about-us-vision-text">
              <h2>Vår vision</h2>
              <p>
                Vi på Vakansa tror på att en vision måste vara kittlande. Så pass kittlande att den nästan verkar
                revolutionerande, men ändå mitt i prick.
              </p>
              <br />
              <p>
                <b>
                  Vakansas vision är att främja en hållbar stadsutveckling där vi möter människors lokalbehov, utan att
                  det behöver byggas nytt.{" "}
                </b>
              </p>
              <br />
              <p>
                För vi vet en sak och det är att den mest hållbara byggnaden är den som aldrig behöver byggas och att
                byggnader idag står helt eller delvis tomma överallt!
              </p>
              <br />
              <p>
                Vill du gå ihop med oss för att ställa om fastighetsbranschen till att bli hållbar på riktigt? Hör av
                dig! <TextButton label="Kontakta oss" color={"green"} href="/kontakta-oss" size="xxs" />
              </p>
            </div>
          </div>
          <div className={`about-us-founder-section`}>
            <div className={`about-us-founder-section-text`}>
              <h2>Vår grundare</h2>
              <h3>Robin Rushdi</h3>
              <div className="about-us-founder-image-mobile">
                <img src={RobinImage} alt="Porträtt av Robin Rushdi" />
              </div>
              <p>
                Robin Rushdi Al-sálehi är utbildad samhällsplanerare och hållbarhetsspecialist som vigt sin karriär åt
                att jobba med hållbar stadsutveckling.
              </p>
              <br />
              <p>
                Han brukar säga <b>“Den mest hållbara byggnaden är den som aldrig behöver byggas”</b> och grundade
                Vakansa av den enkla anledningen att han vill bidra till städer som är till för människor, där byggnader
                och lokaler används för en mångfald av aktiviteter som lockar en mångfald av individer, istället för att
                stå tomma.
                <br />
                <br />
                <b>
                  Kolla hans TED Talk{" "}
                  <a href="https://www.youtube.com/watch?v=HpAaSOgpi50" target="_blank" rel="noopener noreferrer">
                    här
                  </a>
                  !
                </b>
              </p>
            </div>
            <div className="about-us-founder-image">
              <img src={RobinImage} alt="Porträtt av Robin Rushdi" />
            </div>
          </div>
          <div>
            <div className={`team-section`}>
              <h4>Vårt Team</h4>
              <div className={`about-us-team`}>
                <TeamCard
                  memberImg="klas"
                  firstname="Klas"
                  lastname="Hermodsson"
                  role="Advisor"
                  description="Klas är en erfaren programmerare och mångsysslare. Efter fem år på Amazon startade han eget och hjälper 
        Vakansa med både teknik och strategi."
                />
              </div>
            </div>
          </div>
          <CollaborationsSection />
        </main>
      </div>
    </div>
  );
}

export default AboutUs;
