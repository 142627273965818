import React, { useState } from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import MunicipalityModal from "./MunicipalityModal/MunicipalityModal";
import "./municipalityDropdown.css";
import CloseButton from "../../../../components/Buttons/CloseButton/closeButton";

export default function MunicipalityDropdown({
  selectedMunicipality,
  setSelectedMunicipality,
  closeMunicipalityDropdown,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMunicipalityChange = (municipality) => {
    if (selectedMunicipality.includes(municipality)) {
      setSelectedMunicipality(selectedMunicipality.filter((item) => item !== municipality));
    } else {
      setSelectedMunicipality([...selectedMunicipality, municipality]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedMunicipality([]);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={`municipality-dropdown`}>
      <div className="space-dropdown-header">
        <p className="municipality-top-text">Var?</p>
        <CloseButton onClick={closeMunicipalityDropdown} />
      </div>
      <div className="municipality-content-wrapper">
        <div className="municipality-checkbox-wrapper">
          <Checkbox
            label="Stockholm"
            isChecked={selectedMunicipality.includes("stockholm")}
            onChange={() => handleMunicipalityChange("stockholm")}
          />
          <Checkbox
            label="Uppsala"
            isChecked={selectedMunicipality.includes("uppsala")}
            onChange={() => handleMunicipalityChange("uppsala")}
          />
          <Checkbox
            label="Göteborg"
            isChecked={selectedMunicipality.includes("göteborg")}
            onChange={() => handleMunicipalityChange("göteborg")}
          />
          <Checkbox
            label="Malmö"
            isChecked={selectedMunicipality.includes("malmö")}
            onChange={() => handleMunicipalityChange("malmö")}
          />
        </div>
        <p className="municipality-dropdown-show-more" onClick={handleOpenModal}>
          Visa fler kommuner
        </p>
        {isModalOpen && (
          <MunicipalityModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            selectedMunicipality={selectedMunicipality}
            setSelectedMunicipality={setSelectedMunicipality}
          />
        )}
      </div>
      <hr />
      <div className="municipalitys-button-wrapper">
        <TextButton label="Rensa" size="sm" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}
