export const createSEOUrl = (text) => {
  if (typeof text !== "string") {
    return "";
  }

  // replace "/" with "&" to match the API's format
  const textWithAmpersand = text.replace(/\//g, "&");
  // create URL-friendly string
  const formattedText = textWithAmpersand.toLowerCase().replace(/\s+/g, "-");

  return decodeURIComponent(formattedText);
};
