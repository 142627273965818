import React, { useEffect } from "react";
import Team from "../../assets/illustrations/team2.png";
import "../CoworkingPage/coworkingPage.css";
import ArticleSection from "./ArticleSection/ArticleSection";
// import ActiveLinkButton from "../../components/Buttons/ActiveLinkButton/ActiveLinkButton";
import SEOTags from "../../utils/SEOTags";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

function Coworking() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <SEOTags
        title={"Vad är ett kontorshotell och att hitta ett på Vakansa "}
        description={
          "Kontorshotell är ett utmärkt val för dig som behöver ett billigt och flexibelt alternativ till ett eget kontor utan att fastna i långa och dyra kontrakt."
        }
        canonicalUrl={"/kontorshotell"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="coworking-outer">
        <main className={"coworking-inner"}>
          <div className="intro-img-container">
            <img
              className="intro-img"
              src={Team}
              alt="Företagets logotyp med illustrerade människor som står tillsammans"
            />
          </div>
          <div className="coworking-container">
            <div className="intro-text">
              <h1>Vad är ett kontorshotell och att hitta ett på Vakansa</h1>
              <br />
              <h2>Vad är ett kontorshotell?</h2>
              <p>
                Kontorshotell är som namnet avslöjar, ett hotell med kontorsrum som du kan hyra. På samma sätt som ett
                hotell har sovrum och som du hyr vid behov. En anläggning med tillgängliga kontorsrum helt enkelt!
                <br />
                <br />
                Kontorshotell är ett utmärkt val om du behöver ett smidigt och flexibelt kontor utan att fastna i långa
                och kostsamma kontrakt. Särskilt för dig som småföretagare eller har ett mindre företag som söker en
                billigare lösning på ditt kontorsbehov. I ett kontorshotell har du möjlighet att begränsa din förhyrning
                till ett eget kontorsrum eller flera och få gemensamhetsutrymmen som kök, mötesrum, konferensrum och
                toaletter. Ibland ingår även reception på köpet.
                <br />
                <br />
                Avtalen i dessa anläggningar är oftast utan de långa bindningstider och fasta kostnader som
                traditionella kontorslokaler medför där fastighetsägare önskar 3-5 års avtal, vilket gör det enkelt att
                anpassa efter dina egna behov. Oavsett om ert företag skulle växa eller behöva krympa.
              </p>
              <br />
              <br />
              <h3>Socialt och nätverksvänligt</h3>
              <p>
                Att driva företag kan ibland kännas ensamt, men med ett kontorshotell får du den sociala kontakten som
                många småföretagare saknar. Hos de operatörer som annonserar på Vakansa finns det många som är fyllda
                med företag inom olika branscher, vilket ger dig en fantastisk möjlighet att utöka ditt nätverk. I
                fikarummet kan du träffa andra företagare, bygga relationer och kanske till och med hitta nya
                affärsmöjligheter.
                <br />
                <br />
                Vakansa är här för att göra kontorsuthyrning enkelt och flexibelt för dig. Vi hjälper dig hitta det
                perfekta kontorsrummet i dessa anläggningar så att du kan fokusera på att växa ditt företag.
              </p>
              <br />
              <br />
              <h3>Kontorshotell i Stockholm</h3>
              <p>
                På Vakansa har vi flera samarbeten med operatörer i Stockholm som exempelvis United Spaces, Convendum,
                Quick Office, The Park och flera andra aktörer som har ett stort fokus på Stockholm, men också övriga
                Sverige.
                <br />
                <br />
                Med centrala adresser från Östermalm till Södermalm och citykärnan erbjuder vi på Vakansa tillgång till
                Kontorsrum i dessa anläggningar från flera olika aktörer. Anläggningar i Stockholm närhet till stadens
                puls, restauranger, och kommunikationer. Med all nödvändig infrastruktur på plats kan du fokusera på det
                som är viktigast – att driva och utveckla din verksamhet.
              </p>
              <br />
              <br />
              <h3>Hyr kontorshotell via Vakansa</h3>
              <p>
                Letar du efter ett kontorshotell i Stockholm som kan anpassas efter dina behov? Vakansa erbjuder ett
                brett urval av moderna, flexibla kontorshotell på attraktiva adresser runt om i staden.
                <br />
                <br />
                På <a href="/">Vakansa</a> har vi sådana anläggningar över hela Sverige, med extra fokus på våra
                storstäder. Du kan klicka dig vidare och hitta{" "}
                <a href="/lediga-lokaler/stockholm/kontorshotell&co-working">Kontorshotell i Stockholm</a>,{" "}
                <a href="/lediga-lokaler/malmö/kontorshotell&co-working">Kontorshotell i Malmö</a>,{" "}
                <a href="/lediga-lokaler/göteborg/kontorshotell&co-working">Kontorshotell i Göteborg</a> och{" "}
                <a href="/lediga-lokaler/uppsala/kontorshotell&co-working">Kontorshotell i Uppsala</a>.
                <br />
                <br />
                När du väljer ett kontorshotell via Vakansa behöver du inte oroa dig för allt det praktiska. Allt du
                behöver – från internetanslutning och konferensrum till möbler och reception – är redan på plats. Det
                innebär att du kan flytta in och komma igång med verksamheten direkt, utan att lägga tid på detaljer som
                annars kan stjäla ditt fokus. Tid är pengar, och med hyra ett kontorsrum istället för en hel lokal
                sparar du både och.
                <br />
                <br />
                Hos oss har du möjligheten att välja en arbetsplats i en professionell miljö med tillgång till alla
                nödvändiga faciliteter som just du eller ni skulle behöva. På Vakansa kan du hitta anpassade
                kontorslösningar efter ditt företags unika behov.
                <br />
                <br />
                Oavsett om du är en en startup, frilansare eller ett etablerat företag, så kan vi nog erbjuda något för
                dig. Vi har anläggningar som hyr ut allt från enstaka skrivbord, skräddarsydda lösningar eller ett helt
                kontorslandskap via dessa operatörer och annonserar dessa på <a href="/">Vakansa</a>. Vakansa är din
                portal till en värld av flexibla och moderna kontorslösningar.
              </p>
              <br />
              <br />
              <h4>Läs vår blogg och håll dig uppdaterad!</h4>
              <p>
                Vill du läsa mer om coworking, lokaldelning, hur marknaden utvecklar sig eller hur du kan lokaloptimera
                på ett hållbart sätt?
                <br />
                <br />
                Då ska du läsa vår blogg där vår grundare Robin Rushdi Al-sálehi delar med sig av sina insikter.
                <br />
                <br />
                <a href="/blogg">Läs mer i bloggen här</a>
              </p>
              <RoundedLinkButton
                size="xl"
                href={"/lediga-lokaler/allakommuner/kontorshotell&co-working"}
                label="Hitta kontorshotell/coworking"
                color="green"
              />
            </div>
          </div>
        </main>

        <ArticleSection />
      </div>
    </>
  );
}

export default Coworking;
