import React from "react";
import PropTypes from "prop-types";
import "./closeButton.css";

const CloseButton = ({ onClick }) => {
  return (
    <button className="close-button" onClick={onClick} aria-label="Close">
      <svg viewBox="0 0 24 24" fill="currentColor" height="1.2em" width="1.2em">
        <path d="M13.41 12l4.3-4.29a1 1 0 10-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z" />
      </svg>
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
