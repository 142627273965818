import "./blogIndexPage.css";
import { gql, useQuery } from "@apollo/client";
import SEOTags from "../../utils/SEOTags";
import { BlogCard } from "./BlogCard/BlogCard";
import DOMPurify from "dompurify";
import { useEffect } from "react";
import Spinner from "../../components/Spinner/Spinner";

const GET_PAGES = gql`
  {
    blogposts {
      nodes {
        id
        title
        content
        date
      }
    }
  }
`;

const BlogIndexPage = () => {
  const { loading, error, data } = useQuery(GET_PAGES);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading)
    return (
      <div className="blog-index-spinner-wrapper">
        <Spinner />
      </div>
    );

  if (error) return <p>Ett fel uppstod, vänligen försök igen senare.</p>;

  const posts = data.blogposts.nodes;

  const sanitizedPosts = posts.map((post) => ({
    id: DOMPurify.sanitize(post.id),
    title: DOMPurify.sanitize(post.title),
    content: DOMPurify.sanitize(post.content),
    date: DOMPurify.sanitize(post.date),
  }));

  return (
    <>
      <SEOTags
        title={"Blogg   "}
        description={
          "Upptäck tips och regler för andrahandsuthrning. Vakansas blogg guidar dig genom avtal,lagar och hur du smidigast hyr ut och hyr en bostad."
        }
        canonicalUrl={"/blogg"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="blog-index-container" aria-labelledby="blog-title" aria-describedby="blog-description">
        <h1 id="blog-title">Blogg</h1>
        <h2 id="blog-description">Här kan du läsa Vakansas nya blogginlägg.</h2>

        <BlogCard posts={sanitizedPosts} className="blog-index-posts" />
      </main>
    </>
  );
};

export default BlogIndexPage;
