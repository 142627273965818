import React from "react";

export const landlordFAQItems = [
  {
    title: "Kostar det något att annonsera sin lokal som hyresvärd?",
    content: (
      <>
        Det kostar ingenting att registrera sig, få förfrågningar från hyresgäster eller att annonsera en lokal via{" "}
        <a href="/">Vakansa.</a>
        <br />
        <br />
        Vi är en provisionsbaserad plattform som marknadsför era lokaler och förmedlar era lokaler till hyresgäst som
        hör av sig direkt till er vid intresse. När du som hyresvärd fått kontakt med en hyresgäst ni vill avtala en
        förhyrning med, kommer vi på Vakansa att fakturera er 15% av hyresintäkterna ni får in varje månad i maximalt 12
        månader.
        <br />
        <br />
        Se <a href="/hyr-ut-lokal">Hyr ut lokal</a> för mer info om hur vi tar betalt när vi hittat en hyresgäst åt er
        som börjar hyra av er eller lär våra användarvillkor <a href="/anvandarvillkor">här.</a>
      </>
    ),
  },
  {
    title: "Vad kostar det att använda Vakansa vid uthyrning av lokal?",
    content: (
      <>
        Du förbinder dig inte till någon kostnad genom att använda "Vakansa Bas" utan betalar först när du tecknat ett
        hyresavtal muntligt eller skriftligt med en hyresgäst som Vakansa förmedlat. Detta ska du upplysa Vakansa om så
        fort detta skett.
        <br />
        <br />
        Vid lyckad lokalförmedling tar vi på Vakansa en förmedlingsavgift på 15% av hyresintäkten i upp till 12 månader.
        Med hyresintäkten avser vi hyresbeloppet exklusive moms per månad som Hyresvärden fakturerat. Med hyresbelopp
        avses det totala hyresbeloppet för hela den avtalade hyresperioden, även om hyresperioden är uppdelad i flera
        kortare hyresavtal inom en 12 månadersperiod eller om hyran skulle höjas eller sänkas.
        <br />
        <br />
        12 månadersperioden räknas från fakturadatumet som Hyresvärden satt för sin första inbetalda hyra från
        Hyresgästen. Om hyresgästen fortsätter att hyra efter denna 12 månadersperiod så utgår ingen förmedlingsavgift.
        <br />
        <br />
        Läs mer om Vakansa Bas och Mest på <a href="/hyr-ut-lokal">Hyr ut lokal</a>
      </>
    ),
  },
  {
    title: "Vilken information om min lokal behöver jag för att hyra ut via Vakansa?",
    content: (
      <>
        För varje lokal som läggs upp på Vakansa behöver du ange:{" "}
        <ul className="faq-list">
          <li className="faq-point">Vilken typ av lokal det är.</li>
          <li className="faq-point">Hur stort utrymmet är i kvadratmeter.</li>
          <li className="faq-point">Antal personer som lokalen är lämplig för (se brandföreskrifter).</li>
          <li className="faq-point">
            En beskrivning av lokalen där du kan ange hur man tar sig till lokalen, vad som gör den unik och vilka
            aktiviteter som inte kan göras här.
          </li>
          <li className="faq-point">Vilka bekvämligheter som ingår samt minst fyra (4) bilder på lokalen.</li>
        </ul>
        <br />
        Önskar ni hjälp med detta är ni välkomna att kontakta oss på{" "}
        <a href="mailto:info@vakansa.se">info@vakansa.se</a>
        <br />
        <br />
        OBS!: Det är inte tillåtet att uppge kontaktuppgifter eller andra ledande uppgifter i beskrivningstexten som
        skulle göra att ni kringgår Vakansas tjänster och våra användarvillkor. Vid upptäckt kommer vi debitera er 500
        kr/annons ex moms i straffavgift
      </>
    ),
  },
  {
    title: "Hur hyr jag ut min lokal via Vakansa?",
    content: (
      <>
        För att kunna hyra ut din lokal behöver du först <a href="/registrera-konto">registrera ett konto</a> för att
        skapa annonser i Vakansa. Efter att du aktiverat ditt konto via aktiveringsmejlet som skickats till den mejl du
        angett när du skapat kontot, kan du logga in på ditt konto. Väl inloggad kommer du till din egna portal, även
        kallad “mina sidor”, på <a href="/">Vakansa</a> där du kan “skapa annons” i din portal. Här ovan under rubriken
        "Vilken information om min lokal behöver jag för att hyra ut via Vakansa?" kan du hitta svar på vilka frågor du
        behöver svara på för att skapa en annons. En väldigt smidig och lätt process enligt våra befintliga användare.
      </>
    ),
  },
  {
    title: "Hur går uthyrningsprocessen till?",
    content: (
      <>
        När du publicerat en annons för din lokal kan besökare anmäla intresse på er/era annonser genom att skicka en
        förfrågan. Dessa skickas till dig via oss på Vakansa i form av ett "matchningsmejl" som representerar
        lokalförmedlingen. Det är via detta matchningsmejl som vi följer uthyrningsprocessen. Ni får i matchningsmejlet
        information om hyresgästens kontaktuppgifter, organisationsnummer och behovsbeskrivning. Vidare kan ni välja att
        kontakta hyresgästen och ta en visning eller avböja uthyrning. När ni väl genomfört en visning och kommit
        överens om en förhyrning så ordnar ni med avtal och allt det praktiska själva för att gå i mål med
        uthyrningsprocessen. Oavsett om en uthyrning blir av eller inte är det ert ansvar att informera oss på Vakansa
        om hur det går.
        <br />
        <br />
        OBS: För att vi på Vakansa ska kunna upprätthålla vårt ansvar åtar Ni er som användare av våra tjänster, att
        besvara våra kontrollfrågor om hur uthyrningsprocessen med förmedlad part fortskrider. Om du som Hyresvärd
        underlåter dig att svara, efter att vi gjort våra tre (3) kontaktförsök, utgår en straffavgift. Om du som
        Hyresgäst underlåter dig att svara, efter att vi gjort våra tre (3) kontaktförsök, kommer en straffavgift om 500
        kr debiteras din organisation. Detta i enlighet med våra användarvillkor.
      </>
    ),
  },
  {
    title: "Kan man tacka nej till hyresgäster ni förmedlar?",
    content: (
      <>
        Så länge er annons eller annonser är aktiva kan ni ta emot hur många förfrågningar från potentiella hyresgäster
        som helst. Ni har alltid slutordet och vi hjälper er gärna med att skriva avtal när ni väl bestämmer er för att
        ta emot en hyresgäst från oss.
      </>
    ),
  },
  {
    title: "Hur marknadsför Vakansa min yta?",
    content: (
      <>
        I dagens digitala värld så är det svårt att tränga igenom bruset. Detta får du hjälp med helt kostnadsfritt på
        Vakansa genom att skapa annonser och publicera dina lokaler för uthyrning. Vi jobbar jämt och ständigt med
        exponeringen av annonser via sociala medier, genom att ranka på sökmotorer som Google och via våra
        samarbetspartners. Du får hyresgästförfrågningar och väljer själv vilka du vill ta emot eller neka.
        <br />
        <br />
        Vi vill göra det så enkelt som möjligt för fler att välja andrahands lokaler i första hand!
      </>
    ),
  },
  {
    title: "Kan ni hjälpa till med att skriva kontrakt parterna emellan?",
    content: (
      <>
        Vakansa har två prismodeller, Vakansa Bas och Vakansa Mest, varav Vakansa Mest omfattar bland annat att hjälpa
        till att skriva ett lämpligt hyresavtal. Önskar ni enbart hjälp med att upprätta ett avtal ber vi er återkomma
        för att kunna ge er ett fast pris för den tjänsten.
      </>
    ),
  },
  {
    title: "Är min lokal försäkrad via Vakansa?",
    content: (
      <>
        Vakansa ansvarar inte för skador som uppkommer i lokaler, på fönster, skyltfönster, dörrar eller portar som hör
        till lokaler eller som finns i direkt anslutning till lokaler. Detta gäller oavsett vem eller vad som orsakat
        skadan. Varje Hyresvärd och Hyresgäst ansvarar för sitt eget försäkringsbehov och vi rekommenderar att man
        åtminstone ska ha en verksamhetsförsäkring.
        <br />
        <br />
        Vi på Vakansa kan ordna en särskild försäkring vid samnyttjande av lokaler mellan flera parter, via vår
        försäkringspartner.
      </>
    ),
  },
  {
    title: "Vad ingår i ert Vakansa Mest upplägg?",
    content: (
      <>
        Hjälp med uthyrning av lokal: Vakansa Mest innebär att vi sköter de delar av uthyrningen eller samnyttjandet som
        du som hyresvärd (fastighetsägare eller kontraktsägande hyresgäst) behöver avlastning med.
        <br />
        <br />
        Detta är en tjänst som finns utöver Vakansa Bas nivån där du som hyresvärd kan registrera ett konto, publicera
        annonser gratis och få förfrågningar på dina lokaler som du hanterar själv.
        <br />
        <br />
        Med Vakansa Mest hjälper vi alltså dig med det praktiska utöver att hitta en hyresgäst.
        <ul className="faq-list">
          <li className="faq-point">Att upprätta ett andrahandskontrakt parterna emellan.</li>
          <li className="faq-point">
            Ha koll på vad som gäller rörande moms, oavsett om lokalen är en momsad lokal (en lokal som hyrs ut ex moms)
            eller icke momsad lokal (en lokal där ingen moms läggs på hyran).
          </li>
          <li className="faq-point">Ordna med lämpligt bokningssystem.</li>
          <li className="faq-point">Implementera ett modernt digitalt låssystem.</li>
          <li className="faq-point">Kolla upp försäkringsfrågor och hitta lämplig försäkring.</li>
        </ul>
        <br />
        Vi kan också vid behov hjälpa till med andra administrativa och operativa uppgifter såsom:
        <ul className="faq-list">
          <li className="faq-point">Visningar.</li>
          <li className="faq-point">Kreditupplysningar.</li>
          <li className="faq-point">Deposition.</li>
          <li className="faq-point">Hyresinbetalningar.</li>
          <li className="faq-point">
            En "hot-line", likt en jour, som ni kan ringa vid behov av hjälp, felanmälan eller support att hantera vissa
            frågor.
          </li>
          <li className="faq-point">Särskild försäkring för samnyttjande av lokaler mellan flera parter.</li>
        </ul>
        <br />
        Med andra ord - ni hanterar allt ovan om ni väljer vår Vakansa Bas modell. Vilket sker automatiskt när ni
        registrerar ett konto och skapar annonser. Läs mer om{" "}
        <a href="/blogg/hyr-ut-din-lokal-med-vakansa-mest">Vakansa Mest här.</a>
      </>
    ),
  },
  {
    title: "Vad kostar Vakansa mest?",
    content: (
      <>
        Det är pris på förfrågan som gäller då det beror på ert specifika behov, era lokaler och hur mycket ni önskar
        att vi hjälper till i relation till det ni själva vill och/eller kan göra.
      </>
    ),
  },
  {
    title: "Får jag annonsera på andra ställen samtidigt?",
    content: (
      <>
        Vi på Vakansa kräver ingen ensamrätt och vill inte låsa in företag eller fastighetsägare som önskar hyra ut sina
        lokaler. Vill ni använda er av andras digitala tjänster är det fritt fram.
      </>
    ),
  },
  {
    title: "Vad ska jag ta betalt för min yta?",
    content: (
      <>
        Enkelt beskrivet så är det helt upp till er och i relation till vad som är rimligt i ert närområde. Vårt tips är
        att lägga sig lite lägre för att locka intresserade hyresgäster och därefter diskutera tillägg som skrivare,
        fika, parkering, mötesrum, bredband, reception och andra tjänster som ni kan lägga till. Ett annat sätt är
        skriva ett all-inclusive pris! Det är så coworking aktörer gör idag på marknaden.
        <br />
        <br />
        Vill ni ha hjälp med prissättning så kan ni med fördel höra av er till oss på{" "}
        <a href="mailto:info@vakansa.se">info@vakansa.se</a> med bilder, adress, kvadratmeter ni önskar hyra ut och
        annan information så hjälper vi er.
      </>
    ),
  },
  {
    title: "Hanterar eller begär Vakansa depositioner?",
    content: (
      <>
        Vakansa tar inte och hanterar inga depositioner via vår plattform, men kan vara behjälplig i frågor som rör
        depositioner. Vi rekommenderar alltid Hyresvärdar att ta en deposition i syfte att ha en säkerhet vid eventuella
        framtida risker och det är även sedvanligt att lämna en deposition som Hyresgäst. Depositionen brukar ligga på
        mellan 1-6 månadshyror för längre uthyrningar eller en kännbar summa vid kortare uthyrningar, beroende på
        lokalens storlek och ändamål. Vi rekommenderar även att alltid ta betalt i förskott för en förhyrning, på samma
        sätt som man gör med en bostadshyresrätt.
      </>
    ),
  },
  {
    title: "Hur sker betalningen och vilka är betalningsvillkoren?",
    content: (
      <>
        Fakturering av förmedlingsavgiften sker i efterskott digitalt och skickas som PDF faktura från vårt
        bokföringsprogram Bokio. Ni kan välja om ni vill ha en faktura varje månad eller kvartalsvis.
        <br />
        <br />
        Betalningsvillkor är 30 dagar netto. Mervärdesskatt tillkommer på samtliga belopp enligt dessa villkor. Våra
        fullständiga användarvillkor finner du här - <a href="/anvandarvillkor">Användarvillkor.</a>
      </>
    ),
  },
  {
    title: "Tar ni någon kreditupplysning på hyresgästerna?",
    content: (
      <>
        Vi har som krav på Vakansa att alla hyresgäster måste ange organisationsnummer för att kunna skicka en förfrågan
        på lokal. Detta ger er som hyresvärd möjligheten att göra en kreditupplysning själva. Med Vakansa Bas tar vi
        inte ansvar för kreditupplysningar. Behöver ni hjälp med detta hänvisar vi till Vakansa Mest.
      </>
    ),
  },
  {
    title: "Vad förbinder jag mig till när jag använder Vakansa?",
    content: (
      <>
        Du förbinder dig att följa våra användarvillkor. Dessa finner du här -{" "}
        <a href="/anvandarvillkor">Användarvillkor.</a>
      </>
    ),
  },
];
