import React, { useState, useEffect } from "react";
import { backend } from "../../backend";
import axios from "axios";
import { logAxiosError } from "../../logging/error";
import UnitTypeSection from "./UnitTypeSection/UnitTypeSection";
import "./popularAdsPage.css";
import SEOTags from "../../utils/SEOTags";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

const PopularAdsPage = () => {
  const [ads, setAds] = useState([]);

  const fetchFilteredAds = async (filterParams) => {
    try {
      const { data } = await axios.get(backend("/api/listings/filter"), {
        params: filterParams,
      });
      return { ads: data.ads };
    } catch (error) {
      logAxiosError(error);
      throw error;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
    const filterParams = {};
    fetchFilteredAds(filterParams)
      .then((result) => {
        setAds(result.ads);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });
  }, []);
  return (
    <div className="popular-ads-outer">
      <SEOTags
        title={"Populära Lokaltyper   "}
        description={
          "På Vakansa hittar du den perfekta lokalen för dina behov, vare sig det är kontor, biograf, konferensrum eller idrottshall, i hela landet."
        }
        canonicalUrl={"/populara-lokaltyper"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="popular-ads-inner">
        <main className="outer-popular-ads-copy">
          <div className="popular-ads-copy-wrapper">
            <div className="popular-ads-copy-intro">
              <h1>Populära Lokaltyper</h1>
              <br />
              <h2>
                Hitta din nästa eventlokal, möteslokal eller kanske biograf på <a href="https://vakansa.se">Vakansa</a>
              </h2>
              <br />
              <p>
                Det finns massvis med populära lokaltyper på <a href="https://vakansa.se">Vakansa.se</a> och vi förstår
                vikten av att hitta rätt lokal som uppfyller dina krav och behov. Oavsett om du letar efter ett{" "}
                <a href="/lediga-lokaler/stockholm/kontorshotell%2Fco-working/vasagatan-7/672de1caf793f5deafc7e30a">
                  kontor på Vasagatan
                </a>
                ,{" "}
                <a href="/lediga-lokaler/solna/biograf/westfield-mall-of-scandinavia-stjarntorget-2/651f0ec986b88800081b8207">
                  biograf på mall of scandinavia i Solna
                </a>
                ,{" "}
                <a href="/lediga-lokaler/goteborg/moteslokal/haga-ostergata/65e73151b2a7a2000879a450">
                  konferensrum i Göteborg
                </a>{" "}
                eller kanske en idrottshall i våra storstäder kan vi på Vakansa hjälpa dig.
              </p>
              <br />
              <br />
              <br />
              <h3>Upptäck vårt breda utbud av outnyttjade lokaler:</h3>
              <p>
                Vakansa är Sveriges enda nischade plattform för andrahandslokaler, med helt unika lokaler som egentligen
                är tagna, men som vi öppnar upp för dig!
                <br />
                <br />
                Vi har identifierat 15 olika populära lokaltyper som vi synliggör och tillgängliggör via vår plattform.
                Allt för att du ska kunna hitta exakt den lokal du söker efter och inse vilka möjligheter som faktiskt
                finns av att hyra lokaler i andrahand.
              </p>
            </div>
            <div className="popular-ads-copy-list-wrapper">
              <div className="popular-ads-copy-list">
                <ul>
                  <li>
                    <a href="/lediga-lokaler/allakommuner/kontorslokal-kontorshotell&co-working">
                      Kontor, kontorshotell och coworking:
                    </a>{" "}
                    Vi har alla möjliga kontorslösningar till allt från startups till medelstora företag. Välj mellan
                    privata kontorsrum i kontorshotell, eget kontor eller flexibla coworking ytor som erbjuder allt från
                    möbler till teknik och gemenskap.
                  </li>

                  <li>
                    <a href="/lediga-lokaler/allakommuner/möteslokal">Möteslokaler:</a> På vår plattform finns allt från
                    enkla mötesrum till professionella konferenslokaler att hyra per timme eller dag. Hör av dig direkt
                    till hyresvärdarna via Vakansa och ta reda på om de är utrustade med all nödvändig teknologi för
                    just era möten.
                  </li>

                  <li>
                    <a href="/lediga-lokaler/allakommuner/idrott&danslokal">
                      Idrottshallar, gymnastiksalar och danslokaler:
                    </a>{" "}
                    Vakansa är den enda plattformen idag som även har lokaler inom idrott, dans och gymnastik.
                    Anpassningsbara lokaler för en mängd aktiviteter där du eller din organisation kan ordna med allt
                    från yoga, gympa till streetdance.
                  </li>

                  <li>
                    <a href="/lediga-lokaler/allakommuner/eventlokal">Eventlokaler:</a> Planera ditt nästa event som en
                    julfest, halloween eller sommarfest genom att besöka någon av de lokaler vi har på vår plattform. Vi
                    har flera eventlokaler som passar perfekt för allt från företagsevent, konferenser till privata
                    festligheter.
                  </li>
                </ul>
                <br />
                <p>
                  Varje outnyttjad lokal är en förlorad resurs. Pengar som går till uppvärmd luft. Genom Vakansa kan du
                  därför hitta billiga alternativ, men också bidra till en mer hållbar lokalanvändning genom att
                  maximera lokalernas nyttjandegrad och minska miljöpåverkan från onödigt nybygge.
                  <br />
                  <br />
                  Vi har gjort det till vår mission att lokaler ska användas, inte stå tomma i onödan!
                </p>
                <br />
                <br />
                <h3>Lokaler över hela Sverige</h3>
                <p>
                  Använd sökord som "hyr coworkking i Stockholm" eller "hyra danslokal i Malmö" för att snabbt hitta vad
                  du letar efter. Vare sig du är en entreprenör, ett växande företag eller en kreativ själ som letar
                  efter den perfekta arbetsplatsen eller dansstudion, är <a href="https://vakansa.se">Vakansa.se</a> här
                  för att hjälpa dig hitta din idealiska lokal. Vi har:
                </p>
                <br />
                <ul>
                  <li>
                    Lokaler i <a href="/lediga-lokaler/stockholm">Stockholm</a>,{" "}
                    <a href="/lediga-lokaler/malmö">Malmö</a>, <a href="/lediga-lokaler/uppsala">Uppsala</a>,{" "}
                    <a href="/lediga-lokaler/göteborg">Göteborg</a> och andra städer.
                  </li>
                  <li>Våra hyresvärdar erbjuder flexibla hyresavtal och konkurrenskraftiga priser.</li>
                  <li>En användarvänlig plattform som gör det enkelt att söka och jämföra olika lokaler.</li>
                  <li>Professionell support och personlig service för att säkerställa en smidig uthyrningsprocess.</li>
                </ul>
                <br />
                <br />
                <h3>Hyr ut lokal via Vakansa så kan den också bli populär!</h3>
                <p>
                  Många fastighetsägare och förstahandshyresgäster där ute har lokaler som står helt eller delvis tomma.
                  Ytor som bara väntar på att aktiveras med rätt hyresgäster och verksamheter. Sitter du på en lokal som
                  står oanvänd eller dåligt nyttjad? Hyr ut lokal enkelt och flexibelt med Vakansa. Vi erbjuder en
                  mångsidig plattform där du kan anpassa din uthyrning efter dina önskemål, oavsett om du föredrar
                  korttids- eller långtidsuthyrning.
                  <br />
                  <br />
                  Hyr ut den genom att registrera ett konto och skapa en annons via Vakansa.
                  <br />
                  <br />
                  <RoundedLinkButton label={"Hyr ut lokal"} color={"whiteExtra"} size={"md"} href={"/hyr-ut-lokal"} />
                  <br />
                  <br />
                  <br />
                </p>
                <h3>Vanliga frågor och svar</h3>
                <p>
                  Söker du svar på några specifika frågor kring hur det är att vara hyresvärd eller hyresgäst via
                  Vakansa?
                  <br />
                  <br />
                  Vi har samlat alla möjliga svar på de vanligaste frågorna som vi fått från våra användare genom åren
                  och som du kan hitta genom att klicka nedan.
                  <br />
                  <br />
                  <RoundedLinkButton
                    label={"Frågor & svar"}
                    color={"whiteExtra"}
                    size={"md"}
                    href={"/fragor-och-svar"}
                  />
                  <br />
                  <br />
                  Följ vår utveckling och läs det senaste om oss i vår nya blogg! Här kan du hitta information om
                  branschen i stort, om hur det ser ut på lokalmarknaden och hur hållbarhetsfrågorna tangerar det vi
                  gör.
                  <br />
                  <br />
                  Det brukar sägas att bygg- och fastighetsbranschen är samhällets motor och då är det viktigt att hålla
                  koll på vad som sker i branschen. Annars är risken att vi inte bygger en stad för människor och
                  verksamheter, utan människor och verksamheter som anpassar sig för staden.
                  <br />
                  <br />
                  <RoundedLinkButton label={"Gå till bloggen"} color={"whiteExtra"} size={"md"} href={"/blogg"} />
                </p>
              </div>
            </div>

            {/* <div className="popular-ads-copy-outro">
              <p>
                Använd sökord som "hyr kontor i Stockholm" eller "hyra danslokal
                i Malmö" för att snabbt hitta vad du letar efter. Vare sig du är
                en entreprenör, ett växande företag <br /> eller en kreativ själ
                som letar efter den perfekta arbetsplatsen eller dansstudion, är{" "}
                <a href="https://vakansa.se">Vakansa.se</a> här för att hjälpa
                dig hitta din idealiska lokal.
              </p>

              <h2>
                Sök efter din drömlokal på{" "}
                <a href="https://vakansa.se">Vakansa.se</a> idag och upptäck en
                plats som inte bara möter, utan överträffar dina förväntningar!
              </h2>
            </div> */}
          </div>
          <UnitTypeSection listings={ads} />
        </main>
      </div>
    </div>
  );
};

export default PopularAdsPage;
