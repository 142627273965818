import React from "react";

export const vakansaFAQItems = [
  {
    title: "Vi ställde frågan till ChatGPT...",
    content: (
      <>
        Hej GPT, vad är Vakansa för något?
        <br />
        "Vakansa är en plattform som fokuserar på att möjliggöra delning och effektiv användning av lokaler. Plattformen
        är skapad för att hjälpa företag, föreningar, organisationer och privatpersoner att hyra ut sina tomma eller
        outnyttjade lokaler till andra som behöver dem, vare sig det gäller per timme, dag, vecka eller längre
        perioder."
        <br />
        <br />
        Vad gör Vakansa unikt?
        <ol className="faq-list">
          <li className="faq-point">
            <span className="text-bold">Delningsekonomi för lokaler</span>
            <br />
            Vakansa fungerar likt Airbnb, men för lokaler av olika slag – kontor, idrottshallar, skolor,
            föreningslokaler, teatrar, biografer och mycket mer. Syftet är att maximera nyttjandet av befintliga ytor
            och samtidigt minska kostnader och klimatpåverkan.
          </li>
          <li className="faq-point">
            <span className="text-bold">Enkel användning</span>
            <br />
            Genom en användarvänlig plattform kan uthyrare enkelt skapa annonser för sina lokaler, och hyresgäster kan
            snabbt hitta rätt plats för sitt ändamål.
          </li>
          <li className="faq-point">
            <span className="text-bold">Tjänster och stöd</span>
            <br />
            Vakansa erbjuder mer än bara en annonsplattform – de hjälper till med hantering av avtal, försäkringar,
            bokningssystem och andra administrativa frågor, vilket minskar friktionen i delningsprocessen.
          </li>
          <li className="faq-point">
            <span className="text-bold">Hållbarhetsfokus</span>
            <br />
            Visionen är att skapa en mer hållbar stadsutveckling genom att utnyttja redan existerande byggnader istället
            för att bygga nya, vilket minskar resursförbrukningen och klimatpåverkan.
          </li>
          <li className="faq-point">
            <span className="text-bold">Brett utbud av lokaltyper</span>
            <br />
            Plattformen täcker en bred kategori av lokaler för alla möjliga behov – från coworking och konferenslokaler
            till kreativa utrymmen och evenemangsplatser.
          </li>
        </ol>
        <br />
        Vakansa riktar sig till:
        <ul className="faq-list">
          <li className="faq-point">
            <span className="text-bold">Företag och organisationer</span> som vill sänka sina lokalkostnader eller hitta
            flexibla lokallösningar.
          </li>
          <li className="faq-point">
            <span className="text-bold">Föreningar och kulturaktörer</span> som söker tillgängliga och prisvärda lokaler
            för sina verksamheter.
          </li>
          <li className="faq-point">
            <span className="text-bold">Privatpersoner</span> eller småföretagare som behöver tillfälliga arbetsplatser
            eller evenemangslokaler.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Vår grundare berättar om vår vision och mission",
    content: (
      <>
        På Vakansa tror på att en vision måste vara kittlande. Så pass kittlande att den nästan verkar revolutionerande,
        men ändå mitt i prick.
        <br />
        <ul className="faq-list">
          <li className="faq-point">
            <span className="text-bold">
              Vår vision är att främja en hållbar stadsutveckling, där vi möter människors lokalbehov, utan att det ska
              byggas nytt.
            </span>{" "}
            Vi tycker att det är spritt språngande galet att vi bygger massvis med nya kvadratmeter utan att ens försöka
            dela de vi redan har byggt. Att det är fullkomligt skevt att vi tar ifrån andra människor och våra framtida
            generationers möjligheter att leva hållbara liv, bara för att vi vill ha mer och mer.
          </li>
          <li className="faq-point">
            Därför är vår mission att synliggöra och tillgängliggöra olika lokaler för alla möjliga tillfällen. Lokaler
            ska användas, inte stå helt eller delvis tomma i våra städer.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Vakansas mål",
    content: (
      <>
        Vakansas mål är att ingen förening, ingen organisation och inget företag ska behöva stå eller falla på grund av
        en lokal. Vilket vi tyvärr sett under de gångna åren och skrivit om{" "}
        <a href="/blogg/hyra-ut-lokal-för-att-sänka-lokalkostnader">här</a>. Vi vill inte att verksamheter ska betala
        100% av hyran, för 10-20% av tiden och enbart använda 50% av ytan. Pengarna ska inte gå till uppvärmd luft, utan
        ska användas av verksamheten. Det går att sänka lokalkostnader genom att dela lokaler med andra och göra det
        billigare för alla!
        <br />
        <br />
        Genom att sammanföra outnyttjad yta i fastigheter såsom kontor, kontorshotell, biografer, danslokaler, skolor,
        idrottshallar, slöjdsalar, samlingslokaler, konferenslokaler och föreningslokaler med efterfrågan från
        potentiella hyresgäster, ger Vakansa hyresgäster tillgång till lokaler som kan hyras per timme, dag, vecka,
        månad och gärna över flera år!
        <br />
        <br />
        För att skapa en väl fungerande digital marknadsplats samarbetar Vakansa med fastighetsägare och hyresgäster av
        alla slag som har oanvända utrymmen. Syftet är att hjälpa dem att utnyttja sitt utrymme mer effektivt och säkert
        genom att dela det med andra hyresgäster som är i behov av flexibla, billiga och passande ytor. Om du själv äger
        ett outnyttjat utrymme som du vill erbjuda på marknadsplatsen, kan du enkelt annonsera dessa genom att klicka{" "}
        <a href="/blogg/hyra-ut-lokal-för-att-sänka-lokalkostnader">här</a>.
      </>
    ),
  },
  {
    title: "Vår grundare myntade ordet tidsvakans - kärnan Vakansas DNA",
    content: (
      <>
        "Tider då befintliga hyresgäster inte nyttjar sina ytor och som kan användas av någon annan hyresgäst,
        tillskillnad från Vakans som anger att en yta inte innehar någon hyresgäst alls."
        <br />
        <br />
        Exempel: en tidsvakans är alltså en tidslucka i en lokal med en hyresgäst och som kan användas av någon annan.
        Antingen genom uthyrning eller utlåning. Det kan vara ytor som står tomma kl 17-22 varje måndag, kl 06-15 varje
        fredag eller kl 8-20 lördag och söndag. Det kan vara enstaka tillfällen eller i återkommande flera månader. På
        Vakansa kan du ange vilka tider och hur länge du vill hyra ut en lokal.
        <br />
        <br />
        Vill du veta mer om hur vår grundade myntade ordet “tidsvakans”, men också "ytvakans"? - Se då hans TEDx från
        2016 via Uppsala Universitet <a href="https://www.youtube.com/embed/HpAaSOgpi50">här</a>.
      </>
    ),
  },
  {
    title: "Vilka är fördelarna med att använda Vakansa?",
    content: (
      <>
        <ul className="faq-list">
          <li className="faq-point">Hitta olika lokaltyper efter dina eller era behov.</li>
          <li className="faq-point">Finn lokaler över hela Sverige med vår digitala marknadsplats.</li>
          <li className="faq-point">
            Spara pengar, slipp långa bindningstider och betala för ytor du faktiskt använder, när du behöver dem.
          </li>
          <li className="faq-point">
            Öka nyttjandegraden genom att samnyttja lokaler med andra och gör det billigare för alla. På det sättet kan
            vi minska på antalet lokaler som står helt eller delvis tomma överallt i våra städer.
          </li>
          <li className="faq-point">
            Gå in på en annons och anmäl ditt intresse så återkommer hyresvärden till dig snarast.
          </li>
        </ul>
      </>
    ),
  },
];
