import React from "react";
import "./interested.css";
import { useNavigate } from "react-router-dom";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import SEOTags from "../../utils/SEOTags";

const Interested = () => {
  const navigate = useNavigate();

  return (
    <div className="thank-you-component">
      <SEOTags title={"Tack!   "} canonicalUrl={"/intresseanmalan"} ogImage={"/images/logo/meta-logo.jpg"} />
      <h2>Tack!</h2>
      <p>
        En bekräftelse på din intresseanmälan har nu trillat ner in i din digitala brevlåda. Fortsätt konversationen i
        mejlet med hyresvärden.
      </p>
      <br />
      <br />

      <div className="thank-you-modal-btn-row">
        <button className="thank-you-modal-btn" onClick={() => navigate(-1)}>
          Gå tillbaka till tidigare annons
        </button>
      </div>
      <div className="thank-you-request">
        <p>Vill du ha flera kostnadsfria förslag på flera liknande lokaler?</p>
        <RoundedLinkButton label={"Få gratis lokalförslag"} color={"green"} size={"lg"} href={"/lokalforslag"} />
      </div>
    </div>
  );
};

export default Interested;
