import React from "react";
import "./rentVision.css";
import Sustainable from "../../../assets/icons/sustainable.png";
import Buildings from "../../../assets/images/old-buildings.webp";
import Desk from "../../../assets/images/work-desk.webp";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";

export default function RentVision() {
  const isMobile = window.innerWidth < 580;

  return (
    <>
      {isMobile === true ? (
        <div className="rent-vision">
          <div className="rent-out-mobile">
            <div className="rent-out-text-mobile">
              <h2>Hyr ut lokal</h2>
              <p>
                Vi ser till att era lokaler används, istället för att stå tomma. Hos oss kan du därför enkelt och
                smidigt marknadsföra din lokal för att nå rätt målgrupp. Oavsett om det är per timme, dag, vecka, månad
                eller över flera år, så kan du hyra ut din lokal i andrahand hos oss.
              </p>
              <br />
            </div>

            <div className="rent-out-bottom-text-mobile">
              <p>
                Registrera ett konto på vår plattform idag och börja annonsera kostnadsfritt för att få tillgång till
                tusentals potentiella hyresgäster som hör av sig direkt till er!
              </p>
              <br />
              <RoundedLinkButton label={"Bli hyresvärd"} color={"whiteExtra"} size={"lg"} href={"/hyr-ut-lokal"} />
            </div>
            <img className="rent-out-image hide-on-mobile" src={Desk} alt="man standing by a desk" loading="lazy" />
          </div>

          <div className="vision-mobile">
            <div className="vision-image-container hide-on-mobile">
              <img src={Buildings} className="rent-vision-image" alt="old city scape" loading="lazy" />
              <div className="vision-circle">
                <img src={Sustainable} alt="sustainable house icon" loading="lazy" />
              </div>
            </div>
            <div className="vision-text-mobile">
              <h2>Vår vision</h2>
              <p>
                Vakansa startades med den enkla övertygelsen att det redan finns tillräckligt många byggnader och
                lokaler för alla samhällets aktörer. Vi ser hur byggnader och lokaler står helt eller delvis tomma
                överallt i våra städer. Helt i onödan!
              </p>
            </div>
            <div className="vision-text-bttom-mobile">
              <br />
              <p>
                <strong>
                  Vakansas vision är därför att främja en hållbar stadsutveckling där vi möter människors lokalbehov,
                  utan att det behöver byggas nytt.
                </strong>
              </p>
              <p>
                <br />
                Vår mission är att se till att byggnader används, istället för att stå tomma! Hos oss kan du därför hyra
                och hyra ut olika typer av lokaler till alla möjliga tillfällen.
              </p>
              <br />
              <RoundedLinkButton label={"Läs mer om Vakansa"} color={"whiteExtra"} size={"lg"} href={"/om-oss"} />
            </div>
          </div>
        </div>
      ) : isMobile === false ? (
        <div className="rent-vision">
          <div className="rent-out">
            <div className="rent-out-text">
              <h2>Hyr ut lokal</h2>
              <p>
                Vi ser till att era lokaler används, istället för att stå tomma. Hos oss kan du därför enkelt och
                smidigt marknadsföra din lokal för att nå rätt målgrupp. Oavsett om det är per timme, dag, vecka, månad
                eller över flera år, så kan du hyra ut din lokal i andrahand hos oss.
              </p>
              <br />
              <p>
                Registrera ett konto på vår plattform idag och börja annonsera kostnadsfritt för att få tillgång till
                tusentals potentiella hyresgäster som hör av sig direkt till er!
              </p>
              <br />
              <RoundedLinkButton label={"Bli hyresvärd"} color={"whiteExtra"} size={"lg"} href={"/hyr-ut-lokal"} />
            </div>
            <img className="rent-out-image" src={Desk} alt="man standing by a desk" />
          </div>

          <div className="vision">
            <div className="vision-image-container">
              <img src={Buildings} className="rent-vision-image" alt="old city scape" />
              <div className="vision-circle">
                <img src={Sustainable} alt="sustainable house icon" />
              </div>
            </div>
            <div className="vision-text">
              <h2>Vår vision</h2>
              <p>
                Vakansa startades med den enkla övertygelsen att det redan finns tillräckligt många byggnader och
                lokaler för alla samhällets aktörer. Vi ser hur byggnader och lokaler står helt eller delvis tomma
                överallt i våra städer. Helt i onödan!
              </p>
              <br />
              <p>
                <strong>
                  Vakansas vision är därför att främja en hållbar stadsutveckling där vi möter människors lokalbehov,
                  utan att det behöver byggas nytt.
                </strong>
              </p>
              <p>
                <br />
                Vår mission är att se till att byggnader används, istället för att stå tomma! Hos oss kan du därför hyra
                och hyra ut olika typer av lokaler till alla möjliga tillfällen.
              </p>
              <br />
              <RoundedLinkButton label={"Läs mer om Vakansa"} color={"whiteExtra"} size={"lg"} href={"/om-oss"} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
