import React, { useState } from "react";
import "./Accordion.css";
import arrowDown from "./icons/down-arrow.svg";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="ui styled accordion">
      {items.map((item, index) => {
        const isActive = index === activeIndex;

        return (
          <div key={index} className="accordion-item">
            <div className={`title ${isActive ? "active" : ""}`} onClick={() => onTitleClick(index)}>
              {item.title}
              <span className={`icon ${isActive ? "rotate" : ""}`}>
                <img src={arrowDown} alt="arrow icon" />
              </span>
            </div>
            <div className={`content ${isActive ? "active" : ""}`}>
              <p>{item.content}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
