import React, { useState, useEffect } from "react";
import VerifyUserModal from "../../components/VerifyUserModal/VerifyUserModal";
import IntroSection from "./IntroSection/IntroSection";
import VideoSection from "./VideoSection/VideoSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import Steps from "./Steps/Steps";
import RentVision from "./RentVision/RentVision";
import "./home.css";
import SEOTags from "../../utils/SEOTags";
import { LatestNews } from "./LatestNews/LatestNews";

export default function Home({ verifyUser = false }) {
  const [showVerifyModal, setShowVerifyModal] = useState(verifyUser);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <main className="home-main-container">
      <SEOTags
        title={"Hyr lokal eller hyr ut lokal i andrahand med Vakansa idag!"}
        description={
          "Att hyra & hyra ut lokaler har aldrig varit enklare! På Vakansas uthyrningsplattform kan du hitta billiga lediga lokaler i första- och andrahand!"
        }
        canonicalUrl={"/"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <IntroSection />
      <VideoSection />
      <Steps />
      <RentVision />
      <TestimonialSection />
      <LatestNews />
      {showVerifyModal && <VerifyUserModal setShowModal={setShowVerifyModal} />}
    </main>
  );
}
