import React from "react";
import Renter from "./Renter/Renter";
import SEOTags from "../../utils/SEOTags";
import "./forRenter.css";
import { Link } from "react-router-dom";

export default function ForRenter() {
  return (
    <>
      <SEOTags
        title={"Att hyra en lokal för din verksamhet - 5 enkla steg   "}
        description={
          "Att hyra en lokal & förhoppningsvis att hitta rätt lokal som hyresgäst är en tidskrävande aktivitet som du gör någon enstaka gång i ditt liv."
        }
        canonicalUrl={"/hyr-en-lokal"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main>
        <div className="forrenter-container">
          <div className="forrenter-intro-content">
            <div className="forrenter-intro-text">
              <h1>Hyra en lokal</h1>
              <h2>Att hyra lokal ska vara enkelt</h2>
              <p>
                Att hyra en lokal och förhoppningsvis att hitta rätt lokal som hyresgäst är en tidskrävande aktivitet
                som de allra flesta av oss gör någon enstaka gång i våra liv. Det kräver att man kan jämföra lokaler,
                förstå sig på hyreskontrakt, hur marknaden ser ut i ett givet område och vilka lagarna är som gäller.
                <br />
                <br />
                Beroende på vilken verksamhet man bedriver, hur många man är i bolaget och vilka framtidsplaner man har,
                spelar lokalerna olika roller. En lokal kan vara allt från en fabrik inom tillverkningsindustrin till en
                social mötesplats utformad för att locka spetskompetens och talanger inom sin bransch. Kraven som ställs
                när man väljer ny lokal skiljer sig mycket åt och därför är det viktigt att man har en tydlig kravbild
                som hyresgäst.
                <br />
                <br />
                När du är ute och letar efter en lokal att hyra bör du ha några saker i åtanke:
              </p>
              <br />
              <br />
              <ol>
                <li>
                  Vad har ni för verksamhet och vilka aktiviteter ska hållas i lokalen? (Detta ska framgå i hyresavtalet
                  och i dialogen med hyresvärden)
                </li>
                <li>
                  Har ni funderat på läge och att ta höjd för framtida behov från verksamheten eller anställda? (Att
                  välja rätt läge och inkludera medarbetare är A och O i dagens värld med distansarbete)
                </li>
                <li>Hur länge ska ni hyra lokalen? (Klarar ni rent ekonomiskt att hyra i 3, 5 eller 10 år?)</li>
              </ol>
              <br />
              <br />
              <p>
                <b>Obs!:</b> Om ni är en omomsad verksamhet ska ni hyra en omomsad lokal, dvs en lokal där hyresvärden
                inte tar ut moms på lokalhyran. Dessa lokaler har tyvärr minskat i antal under de senaste decennierna,
                vilket gjort att organisationer inom föreningslivet, kulturlivet och civilsamhället har det särskilt
                svårt att hitta lokaler de kan efterfråga. Något vi på Vakansa jobbar särskilt mycket med och är
                specialister kring. Vill du läsa mer om omomsade lokaler kan du hitta mer information{" "}
                <Link to="/lediga-lokaler?moms=false">här</Link>
              </p>
              <br />
              <br />
              <h3>Lokaler att hyra i Sverige</h3>
              <p>
                Det finns gott om lokaler att hyra i hela Sverige just nu på grund av lågkonjunkturen och på grund av
                alla konkurser som skett den senaste tiden. Särskilt i storstäderna är vakansgraden relativt hög och ser
                vi på statistik från Objektvision är det tydligt att det finns gott om lokaler att hyra.
                <br />
                <br />
                Att hyra lokal via Vakansa innebär att man både får tillgång till lokaler med förstahandskontrakt, men
                framförallt andrahandsalternativ.
              </p>
              <br />
              <br />
              <h3>Hyra en lokal via Vakansa</h3>
              <p>
                Att hitta rätt lokal för ditt företag, förening och organisation har aldrig varit enklare. På
                Vakansa.se/lediga-lokaler guidar vi dig smidigt genom processen att hitta och hyra den perfekta lokalen
                som matchar dina behov och önskemål. Följ dessa enkla steg för att komma igång:
                <br />
                <br />
                <ol>
                  <li>
                    {" "}
                    <b>Utforska lokaler:</b> Börja med att utforska vårt omfattande utbud av tillgängliga lokaler.
                    Använd vår användarvänliga filtreringsfunktion för att snabbt sortera och hitta lokaler som
                    uppfyller dina specifikationer <Link to="/lediga-lokaler">här.</Link>
                  </li>
                  <li>
                    {" "}
                    <b>Ange dina preferenser:</b> Berätta för oss vad du söker genom att fylla i dina kriterier såsom
                    typ av lokal, önskad storlek, geografiskt läge och budget. Ju mer detaljerad information du ger,
                    desto bättre matchade lokaler kan vi föreslå. <Link to="/lokalforslag">Här</Link>
                  </li>
                  <li>
                    {" "}
                    <b>Skicka förfrågan:</b> När du har hittat en eller flera lokaler som fångar ditt intresse, kan du
                    enkelt skicka en förfrågan direkt i en annons (se exempel på ett kontorshotell i Kungens Kurva{" "}
                    <Link to="/lediga-lokaler/stockholm/kontorshotell&co-working/geometrivägen-3/631b2bc1b95e640008eb1ead">
                      här
                    </Link>
                    ) och få svar på alla dina frågor direkt av hyresvärden. På så sätt kan du enkelt boka en visning.
                    Besök lokalen personligen och skaffa dig en bättre känsla för utrymmet och hur det passar till dina
                    verksamhetsbehov.
                  </li>
                  <li>
                    {" "}
                    <b>Ta ett beslut:</b> När du har hittat din idealiska lokal och är redo att gå vidare, kan Vakansa
                    hjälpa dig genom hela processen att säkra och börja hyra lokalen av hyresvärden.
                  </li>
                  <li>
                    {" "}
                    <b>Flytta in och gör dig hemmastadd: </b> Efter ett framgångsrikt avtal är det dags att flytta in i
                    din nya lokal. Vakansa finns där för att säkerställa att allt går smidigt, från start till slut.
                  </li>
                </ol>
              </p>
              <br />
              <br />
              <h3>Vill du ha hjälp att hitta lokal?</h3>
              <p>
                Ibland kan lokalsökandet vara överväldigande och då kan vi på Vakansa, mot en mindre startavgift från
                5000 kr ex moms, söka upp lokaler åt er och löpande skicka dessa förslag till er. Då kan vi också följa
                med på visningar, ge råd kring avtal och vara ert bollplank för att säkerställa en att ni hyr
                ändamålsenliga lokaler!
                <br />
                <br />
                Hör av er då till vår grundare Robin Rushdi Al-sálehi på{" "}
                <a href="mailto:robin.rushdi@vakansa.se">robin.rushdi@vakansa.se</a> eller per telefon nr:{" "}
                <a href="tel:076-7065662">076-7065662</a>
              </p>
            </div>
          </div>
        </div>
        <Renter />
      </main>
    </>
  );
}
